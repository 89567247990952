import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appSettings } from "../config/AppSettings";
import { darkModeActive } from "../helper/colorScheme";
import { RootState } from ".";

interface IMapViewState {
  mapboxTheme: string;
  darkMode: boolean;
  navigationView: boolean;
  satelliteView: boolean;
  iotCloudData: boolean;
  userFriendlyGlosa: boolean;
}

const initialMapViewState: IMapViewState = {
  mapboxTheme: appSettings.mapboxTheme,
  darkMode: darkModeActive(),
  navigationView: appSettings.navigationView,
  satelliteView: appSettings.satelliteView,
  iotCloudData: appSettings.iotCloudData,
  userFriendlyGlosa: appSettings.userFriendlyGlosa,
};

const mapViewSlice = createSlice({
  name: "mapView",
  initialState: initialMapViewState,
  reducers: {
    mapboxTheme(state, action: PayloadAction<string>) {
      state.mapboxTheme = action.payload;
    },

    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },

    toggleNavigationView(state) {
      state.navigationView = !state.navigationView;
    },

    toggleSatelliteView(state) {
      state.satelliteView = !state.satelliteView;
    },

    toggleIotCloudData(state) {
      state.iotCloudData = !state.iotCloudData;
    },

    toggleUserFriendlyGlosa(state) {
      state.userFriendlyGlosa = !state.userFriendlyGlosa;
    },
  },
});

export const selectMapboxTheme = (state: RootState) =>
  state.mapView.mapboxTheme;

export const selectDarkMode = (state: RootState) => state.mapView.darkMode;

export const selectNavigationView = (state: RootState) =>
  state.mapView.navigationView;

export const selectSatelliteView = (state: RootState) =>
  state.mapView.satelliteView;

export const selectIotCloudData = (state: RootState) =>
  state.mapView.iotCloudData;

export const selectUserFriendlyGlosa = (state: RootState) =>
  state.mapView.userFriendlyGlosa;

export const mapViewActions = mapViewSlice.actions;
export default mapViewSlice.reducer;
