/**
 * @module V2X Provider
 * @description Provides V2X data from abstract V2X sources to the UI layer
 */
import { getDistance } from "geolib";
import { URL as NodeURL } from "url";
import {
  CoreSettings,
  ObuInfo,
  SubscribedEtsiMsg,
  TlSpat,
  WorkerRequestType,
  WorkerResponse,
  WorkerResponseType,
} from "../types/CoreWorker";
import { GeojsonSource } from "../types/GeojsonSource";
import { FeatureCollection, Point } from "@turf/helpers";
import { MatchedLaneLayers } from "../helper/displayMatchedLanes";


export interface EtsiMsg {
  type: string;
}

export type WarningDenm = {
  causeCode: number;
  relevanceDistance: number;
  subCauseCode: number;
  trafficDirection: number;
  distance: number;
};

/**
 * StationCallback
 * @typedef {Object} StationCallback
 * @description Callback function to be called when a new message from a subscribed V2X station or intersection comes in.
 * @property {SubscribedEtsiMsg} type - ETSI type (DENM, CPM, CAM, etc.) of message that the subscription is interested in
 * @property {number} id - Station id or intersection id that the subscription is interested in
 * @property {function} callback - The actual callback function. The function takes an EtsiMsg object and returns nothing.
 */
type StationCallback = {
  type: SubscribedEtsiMsg;
  id: number;
  secondary?: number;
  callback: (etsiMsg: string) => void;
};

export interface IV2XProvider {
  onObuInfo: (cb: (obuInfo: ObuInfo) => void) => void;
  getLatestMessage: (
    messageType: SubscribedEtsiMsg,
    stationId: number,
    secondaryId: number | undefined,
    callback: (etsiMsg: string) => void
  ) => void;
  addMatchedLanePhasesCallback: (
    layerId: string,
    cb: (uuids: string) => void
  ) => void;
  removeMatchedLanePhasesCallback: (layerId: string) => void;
  addGeojsonCallback: (
    geojsonSource: GeojsonSource,
    callback: GeojsonCallback
  ) => void;
  removeGeojsonCallback: (
    geojsonSource: GeojsonSource,
  ) => void;
  updateSettings: (settings: CoreSettings) => void;
  onConnectionStateChange: (cb: (connected: boolean) => void) => void;
  onError: (cb: (err: Error) => void) => void;
  onGlosa: (cb: (glosa: TlSpat[]) => void) => void;
  onDenmWarning: (cb: (denm: WarningDenm[]) => void) => void;
  onLoopbackMsg: (cb: (type: string, fullMessage: string) => void) => void;
}

export type GeojsonCallback = (geojson: GeoJSON.FeatureCollection) => void;

export class V2XProvider implements IV2XProvider {
  private _worker: Worker;
  private _obuInfo?: [ObuInfo, ObuInfo];
  private _onError?: (err: Error) => void;
  private _onGlosa?: (glosa: TlSpat[]) => void;
  private _onDenmWarning?: (denm: WarningDenm[]) => void;
  private _onObuInfo?: (obuInfo: ObuInfo) => void;
  private _onLoopbackMsg?: (type: string, fullMessage: string) => void;
  private _stationCallback?: StationCallback;
  private _onConnectionStateChange?: (connected: boolean) => void;
  private _geojsonCallbacks: Map<GeojsonSource, GeojsonCallback> = new Map();
  private _matchedLaneCallbacks: Map<string, (uuids: string) => void> =
    new Map();

  constructor(
    settings: CoreSettings
  ) {
    this._worker = new Worker(
      new URL("./coreWorker.ts", import.meta.url) as NodeURL
    );

    this._worker.onmessage = this._onMessage;
    this._worker.postMessage({
      type: WorkerRequestType.INIT,
      settings: settings,
    });
  }
  addMatchedLanePhasesCallback = (
    layerId: string,
    cb: (uuids: string) => void
  ) => {
    this._matchedLaneCallbacks.set(layerId, cb);
  };
  removeMatchedLanePhasesCallback = (layerId: string) => {
    this._matchedLaneCallbacks.delete(layerId);
  };
  updateSettings = (coreSettings: CoreSettings) => {
    this._worker.postMessage({
      type: WorkerRequestType.UPDATE_SETTINGS,
      settings: coreSettings,
    });
  };

  onConnectionStateChange = (cb: (connected: boolean) => void) => {
    this._onConnectionStateChange = cb;
  };

  onGlosa = (cb: (glosa: TlSpat[]) => void) => {
    this._onGlosa = cb;
  };

  onDenmWarning = (cb: (denm: WarningDenm[]) => void) => {
    this._onDenmWarning = cb;
  };

  onError = (cb: (err: Error) => void) => {
    this._onError = cb;
  };

  onObuInfo = (cb: (obuInfo: ObuInfo) => void) => {
    this._onObuInfo = cb;
  };

  onLoopbackMsg = (cb: (type: string, fullMessage: string) => void) => {
    this._onLoopbackMsg = cb;
  }

  private _onMessage = (evt: MessageEvent<WorkerResponse>) => {
    switch (evt.data.type) {
      case WorkerResponseType.CONNECTED:
        if (evt.data.error != null) {
          this._onConnectionStateChange?.call(this, false);
          this._onError?.call(this, evt.data.error);
        } else {
          this._onConnectionStateChange?.call(this, true);
        }
        break;
      case WorkerResponseType.OBU_INFO:
        if (evt.data.obuInfo != null) {
          if (this._obuInfo === undefined) {
            this._obuInfo = [evt.data.obuInfo, evt.data.obuInfo];
          } else {
            this._obuInfo = [this._obuInfo[1], evt.data.obuInfo];
          }
          this._onObuInfo?.call(this, evt.data.obuInfo);
        }
        break;
      case WorkerResponseType.GLOSA:
        if (evt.data.glosa != null) {
          this._onGlosa?.call(this, evt.data.glosa);
        }
        break;
      case WorkerResponseType.LOOPBACK:
        if (evt.data.json != null) {
          switch (evt.data.src) {
            case GeojsonSource.DENM:
              this._onLoopbackMsg?.call(this, "DENM", evt.data.json);
              break;
            case GeojsonSource.CPM:
              this._onLoopbackMsg?.call(this, "CPM", evt.data.json);
              break;
            // More loopback message types might be supported in the future
            default:
              break;
          }
        }
        break;
      case WorkerResponseType.GEOJSON:
        if (evt.data.json !== undefined && evt.data.src !== undefined) {
          if (evt.data.src === GeojsonSource.DENM) {
            let geojson: FeatureCollection<Point, WarningDenm> = JSON.parse(
              evt.data.json
            );

            if (this._obuInfo?.[0] !== undefined) {
              const warningDenm = geojson.features.map((denm) => {
                try {
                  const distance = getDistance(
                    { latitude: this._obuInfo!![0].lat, longitude: this._obuInfo!![0].lon },
                    { latitude: denm.geometry.coordinates[1], longitude: denm.geometry.coordinates[0] }
                  );
                  return {
                    ...denm.properties,
                    distance: Math.round(distance)
                  }
                } catch (e: any) {
                  return denm.properties
                }
              })
              this._onDenmWarning?.call(this, warningDenm);
            } else {
              this._onDenmWarning?.call(this, geojson.features.map((denm) => denm.properties));
            }
          }
          this._onStringifiedJson((geojson) =>
            this._geojsonCallbacks.get(evt.data.src!!)?.call(this, geojson)
          )(evt.data.json);
        }
        break;
      case WorkerResponseType.RAW_MSG:
        if (
          evt.data.rawMsg !== undefined &&
          this._stationCallback !== undefined
        ) {
          this._stationCallback?.callback(evt.data.rawMsg)
          this._stationCallback = undefined
        }
        break;
      case WorkerResponseType.MATCHED_LANE_PHASES: {
        if (evt.data.greenLaneUuids) {
          this._matchedLaneCallbacks
            .get(MatchedLaneLayers.GREEN)
            ?.call(this, evt.data.greenLaneUuids);
        }
        if (evt.data.yellowLaneUuids) {
          this._matchedLaneCallbacks
            .get(MatchedLaneLayers.YELLOW)
            ?.call(this, evt.data.yellowLaneUuids);
        }
        if (evt.data.redLaneUuids) {
          this._matchedLaneCallbacks
            .get(MatchedLaneLayers.RED)
            ?.call(this, evt.data.redLaneUuids);
        }
        if (evt.data.darkLaneUuids) {
          this._matchedLaneCallbacks
            .get(MatchedLaneLayers.DARK)
            ?.call(this, evt.data.darkLaneUuids);
        }
        break
      }
      case WorkerResponseType.ERROR:
        if (evt.data.error !== undefined)
          this._onError?.call(this, evt.data.error);
        break;
      default:
        break;
    }
  };

  getLatestMessage = (
    messageType: SubscribedEtsiMsg,
    stationId: number,
    secondaryId: number | undefined,
    callback: (etsiMsg: string) => void
  ) => {
    this._worker.postMessage({
      type: WorkerRequestType.LATEST_MESSAGE,
      messageType,
      stationId: stationId,
      secondaryId: secondaryId,
    });
    this._stationCallback = {
      type: messageType,
      id: stationId,
      secondary: secondaryId,
      callback,
    };
  };

  addGeojsonCallback = (
    geojsonSource: GeojsonSource,
    callback: GeojsonCallback
  ) => {
    this._geojsonCallbacks.set(geojsonSource, callback);
  };

  removeGeojsonCallback = (
    geojsonSource: GeojsonSource,
  ) => {
    this._geojsonCallbacks.delete(geojsonSource);
  };

  private _onStringifiedJson = (cb: GeojsonCallback) => (json: string) => {
    try {
      cb(JSON.parse(json));
    } catch (e: any) {
      this.onError?.call(this, e);
    }
  };
}

