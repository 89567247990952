import { IV2XProvider } from "../services/V2XProvider";
import { IMapboxMap } from "../types/IMapboxMap";
import { featureCollection } from "@turf/helpers";
import { GeojsonSource, id } from "../types/GeojsonSource";

export enum MatchedLaneLayers {
  GREEN = "matched-lanes-green",
  YELLOW = "matched-lanes-yellow",
  RED = "matched-lanes-red",
  DARK = "matched-lanes-dark"
}

export const displayMatchedLanes = (
  v2xService: IV2XProvider,
  mapboxMap: IMapboxMap,
  displayMatchedLanes: boolean
) => {
  [
    MatchedLaneLayers.GREEN,
    MatchedLaneLayers.YELLOW,
    MatchedLaneLayers.RED,
    MatchedLaneLayers.DARK
  ].forEach((id) => {
    if (displayMatchedLanes) {
      mapboxMap.setLayoutProperty(id, "visibility", "visible")
      v2xService.addMatchedLanePhasesCallback(id, (uuids) => {
        mapboxMap.setFilter(id, ["in", ["get","uuid"], uuids]);
      });
    } else {
      mapboxMap.setLayoutProperty(id, "visibility", "none")
      if (mapboxMap.getLayer(id) !== undefined) {
        mapboxMap.setFilter(id, ["in", ["get","uuid"], ""]);
      }
      v2xService.removeMatchedLanePhasesCallback(id);
    }
  });

  if (displayMatchedLanes) {
    // Timeout is necessary, because there might be cued geojson updates for the
    // unmatched lane sources
    setTimeout(() => {
      [
        GeojsonSource.GREEN_LANES,
        GeojsonSource.RED_LANES,
        GeojsonSource.YELLOW_LANES,
        GeojsonSource.DARK_LANES,
      ].forEach((src) => {
        mapboxMap.getSource(id(src))?.setData(
          featureCollection([])
        );
      });
    }, 200);
  }
};
