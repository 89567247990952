import { FeatureCollection } from "@turf/helpers";
import { IoTCloudHHConfig } from "@consider-it/iot-cloud-hh";

export const iotCloudConfig: IoTCloudHHConfig =
{
  requests: [
    {
      url: "https://iot.hamburg.de/v1.0/Things?$filter=Datastreams/properties/serviceName%20eq%20%27HH_STA_StadtRad%27&$orderby=id&$count=true&$select=Locations&$expand=Locations($select=location),Datastreams($select=Observations;$expand=Observations($select=result,id;$orderBy=phenomenonTime%20desc;$top=1))&$top=1000&$resultFormat=GeoJSON",
      updateIntervalSec: 60,
      transformation: (fc: FeatureCollection) => {
        fc.features?.forEach((f) => {
          if (f.properties != null) {
            f.properties.type = "stadtrad";
            f.properties.uuid =
              f.properties["Datastreams/0/Observations/0/id"]?.toString();
          }
        });
        return fc;
      },
    },
    {
      url: "https://iot.hamburg.de/v1.0/Things?$filter=Datastreams/properties/serviceName%20eq%20%27HH_STA_E-Ladestationen%27&$orderby=id&$count=true&$select=Locations&$expand=Locations($select=location),Datastreams($select=Observations;$expand=Observations($select=result,id;$orderBy=phenomenonTime%20desc;$top=1))&$top=1000&$resultFormat=GeoJSON",
      updateIntervalSec: 110,
      transformation: (fc: FeatureCollection) => {
        fc.features?.forEach((f) => {
          if (f.properties != null) {
            const uuid =
              f.properties["Datastreams/0/Observations/0/id"]?.toString();
            const propValues = Object.values(f.properties);
            const occupancy = `${
              propValues.filter((v) => v === "available").length
            }/${propValues.length / 2}`;
            f.properties = {};
            f.properties.uuid = uuid;
            f.properties.occupancy = occupancy;
            f.properties.type = "ladesaeule";
          }
        });
        return fc;
      },
    }
  ],
}