import { featureCollection, FeatureCollection } from "@turf/helpers";
import { useEffect, useState } from "react";

export const useFeatureCache = (): [
  GeoJSON.FeatureCollection,
  React.Dispatch<React.SetStateAction<FeatureCollection>>
] => {
  const [features, setFeatures] = useState(featureCollection([]) as GeoJSON.FeatureCollection)
  const [update, setUpdate] = useState(featureCollection([]) as FeatureCollection)

  useEffect(() => {
    const old = Object.fromEntries(features.features?.map(f => [f.properties?.uuid ?? 0, f]))
    const niu = Object.fromEntries(update.features?.map(f => [f.properties?.uuid ?? 0, f]))
    setFeatures(featureCollection(Object.values({...old, ...niu})))
    // to prevent useEffect loop
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [update, setFeatures])

  return [features, setUpdate]
}