import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appSettings } from "../config/AppSettings";
import { MessageTypeToggle } from "../types/MessageTypes";
import { MessageType } from "../types/MessageTypes";
import { RootState } from ".";

interface IV2xMessageState {
  msgTypes: MessageTypeToggle;
}

const initialV2xMessageState: IV2xMessageState = {
  msgTypes: appSettings.toggleMsgTypes,
};

const v2xMessageSlice = createSlice({
  name: "v2xMessage",
  initialState: initialV2xMessageState,
  reducers: {
    toggleMsgTypes(state, action: PayloadAction<MessageType>) {
      const newArr = { ...state.msgTypes };
      newArr[action.payload] = !newArr[action.payload];
      state.msgTypes = newArr;
    },
  },
});

export const selectToggleMsgTypes = (state: RootState) =>
  state.v2xMessage.msgTypes;

export const v2xMessageActions = v2xMessageSlice.actions;
export default v2xMessageSlice.reducer;
