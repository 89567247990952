import WARNING_CRITICAL_DRIVING_CONDITION from "../../assets/1_criticalDrivingCondition.png";
import WARNING_ACCIDENT from "../../assets/2_accident.png";
import WARNING_ROADWORKS from "../../assets/3_roadWorks.png";
import WARNING_SLIPPERY_ROAD from "../../assets/7_slipperyRoad.png";
import WARNING_WRONG_WAY_DRIVER from "../../assets/14_wrongWayDriverWrong.png";
import WARNING_VISUAL_OBSTRUCTION from "../../assets/18_visualObstruction.png";
import WARNING_SLOW_VEHICLE from "../../assets/26_slowVehicle.png";
import WARNING_OBSTACLE_ON_THE_ROAD from "../../assets/10_obstacleOnTheRoad.png";
import WARNING_ANIMAL_ON_THE_ROAD from "../../assets/11_animalOnTheRoad.png";
import WARNING_HUMAN_PRESENCE_ON_THE_ROAD from "../../assets/12_humanPresenceOnTheRoad.png";
import WARNING_RESCUE_AND_RECOVERY from "../../assets/15_rescueAndRecovery.png";
import WARNING_DANGEROUS_END_OF_QUEUE from "../../assets/27_dangerousEndOfQueue.png";
import WARNING_VEHICLE_BREAKDOWN from "../../assets/91_vehicleBreakdown.png";
import WARNING_STATIONARY_VEHICLE from "../../assets/94_stationaryVehicle.png";
import WARNING_EMERGENCY_VEHICLE from "../../assets/95_emergencyVehicle.png";
import WARNING_COLLISION_RISK from "../../assets/97_collisionRisk.png";
import WARNING_DANGEROUS_SITUATION from "../../assets/99_dangerousSituation.png";
import WARNING_CRITICAL_DRIVING_CONDITION_WHITE from "../../assets/1_criticalDrivingCondition_white.png";
import WARNING_ACCIDENT_WHITE from "../../assets/2_accident_white.png";
import WARNING_ROADWORKS_WHITE from "../../assets/3_roadWorks_white.png";
import WARNING_SLIPPERY_ROAD_WHITE from "../../assets/7_slipperyRoad_white.png";
import WARNING_WRONG_WAY_DRIVER_WHITE from "../../assets/14_wrongWayDriverWrong_white.png";
import WARNING_VISUAL_OBSTRUCTION_WHITE from "../../assets/18_visualObstruction_white.png";
import WARNING_SLOW_VEHICLE_WHITE from "../../assets/26_slowVehicle_white.png";
import WARNING_OBSTACLE_ON_THE_ROAD_WHITE from "../../assets/10_obstacleOnTheRoad_white.png";
import WARNING_ANIMAL_ON_THE_ROAD_WHITE from "../../assets/11_animalOnTheRoad_white.png";
import WARNING_HUMAN_PRESENCE_ON_THE_ROAD_WHITE from "../../assets/12_humanPresenceOnTheRoad_white.png";
import WARNING_DANGEROUS_END_OF_QUEUE_WHITE from "../../assets/27_dangerousEndOfQueue_white.png";
import WARNING_VEHICLE_BREAKDOWN_WHITE from "../../assets/91_vehicleBreakdown_white.png";
import WARNING_STATIONARY_VEHICLE_WHITE from "../../assets/94_stationaryVehicle_white.png";
import WARNING_EMERGENCY_VEHICLE_WHITE from "../../assets/95_emergencyVehicle_white.png";
import { ITSRelease1 } from "@consider-it/etsi-types";
import { t } from "i18next";
import { WarningDenm } from "../../services/V2XProvider";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../store/mapView";

type DenmContainerProps = {
  denm: WarningDenm;
};

const DenmContainer = ({ denm }: DenmContainerProps) => {
  const darkMode = useSelector(selectDarkMode);

  return (
    <div className="denm-container" data-testid="denm-container">
      <img
        data-testid="denm-image"
        src={getDenmImage(denm, darkMode)}
        alt="warning-sign"
      />
      <p data-testid="denm-label">{getWarningSignText(denm)}</p>
    </div>
  );
};

const getDenmImage = (denm: WarningDenm, darkMode: boolean): string => {
  switch (denm.causeCode) {
    case ITSRelease1.CauseCodeType.Accident:
      return darkMode ? WARNING_ACCIDENT_WHITE : WARNING_ACCIDENT;
    case ITSRelease1.CauseCodeType.Aquaplaning:
      return darkMode ? WARNING_SLIPPERY_ROAD_WHITE : WARNING_SLIPPERY_ROAD;
    case ITSRelease1.CauseCodeType.HazardousLocationObstacleOnTheRoad:
      return darkMode ? WARNING_OBSTACLE_ON_THE_ROAD_WHITE : WARNING_OBSTACLE_ON_THE_ROAD;
    case ITSRelease1.CauseCodeType.CollisionRisk:
      return WARNING_COLLISION_RISK;
    case ITSRelease1.CauseCodeType.TrafficCondition:
      return darkMode ? WARNING_CRITICAL_DRIVING_CONDITION_WHITE : WARNING_CRITICAL_DRIVING_CONDITION;
    case ITSRelease1.CauseCodeType.DangerousEndOfQueue:
      return darkMode ? WARNING_DANGEROUS_END_OF_QUEUE_WHITE : WARNING_DANGEROUS_END_OF_QUEUE;
    case ITSRelease1.CauseCodeType.EmergencyVehicleApproaching:
      return darkMode ? WARNING_EMERGENCY_VEHICLE_WHITE : WARNING_EMERGENCY_VEHICLE;
    case ITSRelease1.CauseCodeType.RescueAndRecoveryWorkInProgress:
      return WARNING_RESCUE_AND_RECOVERY;
    case ITSRelease1.CauseCodeType.HazardousLocationAnimalOnTheRoad:
      return darkMode ? WARNING_ANIMAL_ON_THE_ROAD_WHITE : WARNING_ANIMAL_ON_THE_ROAD;
    case ITSRelease1.CauseCodeType.HumanPresenceOnTheRoad:
      return darkMode ? WARNING_HUMAN_PRESENCE_ON_THE_ROAD_WHITE : WARNING_HUMAN_PRESENCE_ON_THE_ROAD;
    case ITSRelease1.CauseCodeType.WrongWayDriving:
      return darkMode ? WARNING_WRONG_WAY_DRIVER_WHITE : WARNING_WRONG_WAY_DRIVER;
    case ITSRelease1.CauseCodeType.AdverseWeatherConditionVisibility:
      return darkMode ? WARNING_VISUAL_OBSTRUCTION_WHITE : WARNING_VISUAL_OBSTRUCTION;
    case ITSRelease1.CauseCodeType.SlowVehicle:
      return darkMode ? WARNING_SLOW_VEHICLE_WHITE : WARNING_SLOW_VEHICLE;
    case ITSRelease1.CauseCodeType.Roadworks:
      return darkMode ? WARNING_ROADWORKS_WHITE : WARNING_ROADWORKS;
    case ITSRelease1.CauseCodeType.StationaryVehicle:
      if (denm.subCauseCode === 2) {
        return darkMode ? WARNING_VEHICLE_BREAKDOWN_WHITE : WARNING_VEHICLE_BREAKDOWN;
      } else if (denm.subCauseCode === 3) {
        return darkMode ? WARNING_ACCIDENT_WHITE : WARNING_ACCIDENT;
      } else {
        return darkMode ? WARNING_STATIONARY_VEHICLE_WHITE : WARNING_STATIONARY_VEHICLE;
      }
    case ITSRelease1.CauseCodeType.VehicleBreakdown:
      return darkMode ? WARNING_VEHICLE_BREAKDOWN_WHITE : WARNING_VEHICLE_BREAKDOWN;
    default:
      return WARNING_DANGEROUS_SITUATION;
  }
};

const getWarningSignText = (denm: WarningDenm): string => {
  const distanceText = denm.distance > 0 ? ` in ${denm.distance}m` : "";

  switch (denm.causeCode) {
    case ITSRelease1.CauseCodeType.Accident:
      return t("denmDanger", { distance: distanceText });
    case ITSRelease1.CauseCodeType.Aquaplaning:
      return t("denmAquaplaning", { distance: distanceText });
    case ITSRelease1.CauseCodeType.TrafficCondition:
      return t("denmTrafficCondition", { distance: distanceText });
    case ITSRelease1.CauseCodeType.WrongWayDriving:
      return t("denmWrongWayDriving", { distance: distanceText });
    case ITSRelease1.CauseCodeType.AdverseWeatherConditionVisibility:
      return t("denmWeatherCondition", { distance: distanceText });
    case ITSRelease1.CauseCodeType.SlowVehicle:
      return t("denmSlowVehicle", { distance: distanceText });
    case ITSRelease1.CauseCodeType.CollisionRisk:
      return t("denmCollision", { distance: distanceText });
    case ITSRelease1.CauseCodeType.DangerousEndOfQueue:
      return t("denmQueueEnd", { distance: distanceText });
    case ITSRelease1.CauseCodeType.RescueAndRecoveryWorkInProgress:
      return t("denmWork", { distance: distanceText });
    case ITSRelease1.CauseCodeType.EmergencyVehicleApproaching:
      return t("denmVehicleApproaching", { distance: distanceText });
    case ITSRelease1.CauseCodeType.HazardousLocationAnimalOnTheRoad:
      return t("denmAnimal", { distance: distanceText });
    case ITSRelease1.CauseCodeType.HazardousLocationObstacleOnTheRoad:
      return t("denmObstacle", { distance: distanceText });
    case ITSRelease1.CauseCodeType.HumanPresenceOnTheRoad:
      return t("denmPedestrian", { distance: distanceText });
    case ITSRelease1.CauseCodeType.Roadworks:
      return t("denmRoadwork", {
        distance: distanceText,
      });
    case ITSRelease1.CauseCodeType.StationaryVehicle:
      if (denm.subCauseCode === 2) {
        return t("denmBrokenDown", { distance: distanceText });
      } else if (denm.subCauseCode === 3) {
        return t("denmPostCrash", { distance: distanceText });
      } else {
        return t("denmStationary", { distance: distanceText });
      }
    case ITSRelease1.CauseCodeType.VehicleBreakdown:
      return t("denmBrokenDown", { distance: distanceText });
    default:
      return t("denmCaution", { distance: distanceText });
  }
};

export default DenmContainer;
