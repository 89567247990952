export type MessageTypeToggle = {
  CAMS: boolean;
  CPMS: boolean;
  DENMS: boolean;
  SPATEMS: boolean;
  IVIMS: boolean;
};

export enum MessageType {
  CAMS = "CAMS",
  CPMS = "CPMS",
  DENMS = "DENMS",
  SPATEMS = "SPATEMS",
  IVIMS = "IVIMS",
}
