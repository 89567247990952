/**
 * @module Layer Component
 * @description Represents the a single Layer of V2X data rendered on the mapbox map
 */
import { featureCollection, FeatureCollection } from "@turf/helpers";
import { useEffect, useState } from "react";
import { IV2XProvider } from "../../services/V2XProvider";
import { GeojsonSource, id } from "../../types/GeojsonSource";
import { IMapboxMap } from "../../types/IMapboxMap";
import { iotCloudConfig } from "../../config/IotCloudConfig";
import { IoTCloudHamburg } from "@consider-it/iot-cloud-hh";
import { useFeatureCache } from "../../hooks/useFeatureCache";
import { useSelector } from "react-redux";
import {
  selectMapboxTheme,
  selectDarkMode,
  selectNavigationView,
  selectSatelliteView,
} from "../../store/mapView";

type LayerProps = {
  showMessages: boolean;
  v2xService: IV2XProvider;
  mapboxMap: IMapboxMap;
  geojsonSource: GeojsonSource;
  geojsonData?: GeoJSON.Feature;
  onUpdate?: (update: GeoJSON.FeatureCollection) => void;
}
/**
 * LayerProps
 * @typedef {Object} LayerProps
 * @description Props passed to the Layer component 
 * @see Layer
 * @property {boolean} showMessages - whether the layer should display incoming V2X messages
 * @property {IV2XProvider} v2xService - provider of incoming V2X data that should be displayed
 * @property {IMapboxMap} mapboxMap - the map instance this layer should be rendered to
 * @property {GeojsonSource} geojsonSource - geojson source to be rendered
 * @property {GeoJSON.Feature} [geojsonData] - optional GeoJSON data to be displayed
 * @property {function} [onUpdate] - callback to be called when the layer has been updated
 */
/**
 * Layer
 * @alias Layer
 * @description Functional React component that represents a mapbox map layer
 * @param {LayerProps} layerProps - props passed to the Layer component
 * @see LayerProps
 */
export const Layer = ({
  showMessages,
  v2xService,
  mapboxMap,
  geojsonSource,
  geojsonData,
  onUpdate,
}: LayerProps) => {
  const [geojson, setGeojson] = useState<GeoJSON.FeatureCollection>(
    featureCollection([])
  );
  const [iotState, setIotState] = useFeatureCache();

  const mapboxTheme = useSelector(selectMapboxTheme);
  const darkMode = useSelector(selectDarkMode);
  const navigationView = useSelector(selectNavigationView);
  const satelliteView = useSelector(selectSatelliteView);

  useEffect(() => {
    let cloudSource: IoTCloudHamburg;
    if (GeojsonSource.IOT_CLOUD_DATA === geojsonSource) {
      const cloudDataCallback = (cloudFeatureCollection: FeatureCollection) => {
        setIotState(cloudFeatureCollection);
      };
      cloudSource = new IoTCloudHamburg(cloudDataCallback, iotCloudConfig);
      cloudSource.connect();
    } else if (GeojsonSource.OBU_POS !== geojsonSource) {
      v2xService.addGeojsonCallback(
        geojsonSource as GeojsonSource,
        setGeojson
      );
    }

    return () => {
      cloudSource?.disconnect();
      if (GeojsonSource.OBU_POS !== geojsonSource) {
        v2xService.removeGeojsonCallback(
          geojsonSource as GeojsonSource,
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationView, darkMode, mapboxTheme, satelliteView]);

  useEffect(() => {
    switch (geojsonSource) {
      case GeojsonSource.OBU_POS:
        mapboxMap.getSource(id(geojsonSource))?.setData(geojsonData ?? featureCollection([]));
        break;
      case GeojsonSource.IOT_CLOUD_DATA:
        mapboxMap.getSource(id(geojsonSource))?.setData(showMessages ? iotState : featureCollection([]));
        break;
      default:
        if (showMessages && !geojson) return;
        onUpdate?.call(this, geojson);
        mapboxMap.getSource(id(geojsonSource))?.setData(
          showMessages ? geojson : featureCollection([])
        );
        break;
    }
  }, [
    showMessages,
    geojson,
    geojsonData,
    mapboxMap,
    onUpdate,
    iotState,
    geojsonSource,
    mapboxTheme,
    darkMode,
    navigationView,
  ]);

  // Makeshift fix, that makes sure the OBU icon definitely shows
  // up once the map style changes. Depending on the client's network connection
  // the hook above might fire before the obu image resource is loaded. The timeout
  // below covers the connectability scenarios in our scope.
  useEffect(() => {
    if (GeojsonSource.OBU_POS === geojsonSource) {
      setTimeout(
        () => mapboxMap.getSource(id(geojsonSource))?.setData(geojsonData ?? featureCollection([])),
        1000
      );
    }
    // We only want this to fire when the map style changes, so we need
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapboxTheme, darkMode, navigationView, satelliteView]);

  return <></>;
};
