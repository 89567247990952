/**
 * @module BugReporting
 * @description Represents a bug reporting component with user input
 */
import { useState } from "react";
import { t } from "i18next";
import * as Sentry from "@sentry/react";
import { SENTRY_URL } from "../../constants/httpVars";

interface BugItemProps {
  placeholder: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  emptyInputMessage: string;
}
/**
 * @typedef {Object} BugItemProps
 * @description Props passed to the BugReporting component 
 * @see BugReporting
 * @property {string} placeholder - Placeholder text for the input field.
 * @property {React.Dispatch<React.SetStateAction<any>>} setValue - Callback function to set a value.
 * @property {string} emptyInputMessage - Error message to display when input is empty.
 */
/**
 * BugReporting
 * @alias BugReporting
 * @description Functional React component that represents a bug reporting
 * @param {BugItemProps} BugItemProps - props passed to the BugReporting component
 * @see BugItemProps
 */
const BugReporting = ({
  placeholder,
  setValue,
  emptyInputMessage
}: BugItemProps) => {
  const [input, setInput] = useState(""); 
  const [emptyInput, setEmptyInput] = useState(false);
  const [errorInput, setErrorInput] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
  };

  const handleChange = async () => {
    if (!input) {
      setEmptyInput(true);
      setErrorInput(false);
      return;
    }

    try {
      await fetch(SENTRY_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description: input }),
      });

      Sentry.captureMessage(input, {
        level: "log"
      });

      setConfirmSubmit(true);
      setTimeout(() => {
        setConfirmSubmit(false);
      }, 5000);

      setEmptyInput(false);
      setValue(input);
      setInput("");
    } catch (error) {
      setErrorInput(true);
      setEmptyInput(false);
      setConfirmSubmit(false);
      setValue("");

      Sentry.captureException(error);
    }
  };

  return (
    <>
      <h1 className="toggle-header">{t("BugReporting")}</h1>
      <div className="input-wrapper">
        <div className="input-field">
          <textarea className="input-textarea"
            rows={5}
            value={input}
            onChange={handleInput}
            placeholder={placeholder}
          />
          <button onClick={() => handleChange()}>{t("send")}</button>
        </div>
        {emptyInput && (
          <p data-testid={`empty-input`} className="error-msg">
            {t(emptyInputMessage)}
          </p>
        )}
        {errorInput && (
          <p data-testid={`empty-input`} className="error-msg">
            {t("bugReportError")}
          </p>
        )}
        {confirmSubmit && (
          <p data-testid={`success-input`} className="success-msg">
            {t("bugReportSuccess")}
          </p>
        )}
      </div>
    </>
  );
};

export default BugReporting;