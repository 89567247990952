/**
 * @module MapStyles
 * @description The styles that are available for the application map. Map styles change how map data (i.e. streets, locations, topology etc.) is rendered on the map, what colors are being used, what icon sets, and in which sizes and density.
 */

/**
 * @var MapStyle
 * @enum {string}
 * @description The currently available styles the user can apply to the application map.
 */
export enum MapStyle {
  /**
   * @member DARK
   * @description Minimalistic style for dark environments and a top-down map view. Uses predominantly dark colors.
   */
  DARK = "Dark",
  /**
   * @member LIGHT
   * @description Minimalistic style for bright environments and a top-down map view. Uses predominantly light colors.
   */
  LIGHT = "Light",
  /**
   * @member DARK_NAV
   * @description Minimalistic style for dark environments and a navigation map view (i.e. follower perspective). Uses predominantly dark colors and isometric icons.
   */
  DARK_NAV = "Dark Navigation",
  /**
   * @member LIGHT_NAV
   * @description Minimalistic style for bright environments and a navigation map view (i.e. follower perspective). Uses predominantly light colors and isometric icons.
   */
  LIGHT_NAV = "Light Navigation",
  /**
   * @member SATELLITE
   * @description Style using satellite imagery.
   */
  SATELLITE = "Satellite",
  /**
   * @member BASIC_OVERCAST
   * @description Feature-rich style displaying buildings and area usages in pastel colors.
   */
  BASIC_OVERCAST = "Basic Overcast",
  /**
   * @member BASIC
   * @description Feature-rich style displaying buildings and area usages in high-contrast colors.
   */
  BASIC = "Basic",
  /**
   * @member AMERICAN_MEMORY
   * @description Gallery style displaying building outlines and streets as if drawn with a light blue pencil.
   */
  AMERICAN_MEMORY = "American Memory",
  /**
   * @member PENCIL
   * @description Gallery style displaying building outlines and streets as a pencil drawing.
   */
  PENCIL = "Pencil",
  /**
   * @member FRANK
   * @description Feature-rich style displaying 3D buildings and area usages in pastel colors.
   */
  FRANK = "Frank",
  /**
   * @member BLUEPRINT
   * @description Gallery style displaying building outlines and streets as in an architectural blueprint.
   */
  BLUEPRINT = "Blueprint",
  /**
   * @member STANDARD
   * @description Gallery style displaying building outlines and streets as in Collins' 1888 Standard Map of London.
   */
  STANDARD = "Standard",
  /**
   * @member MINIMO
   * @description Gallery style displaying building outlines and streets as in a minimalistic, quasi monochrome fashion.
   */
  MINIMO = "Minimo",
  /**
   * @member CLASSIC_DARK
   * @description Legacy consider it style displaying 3D buildings, streets, and area usage in dark colors.
   */
  CLASSIC_DARK = "Classic Dark",
  /**
   * @member CLASSIC_LIGHT
   * @description Legacy consider it style displaying 3D buildings, streets, and area usage in light colors.
   */
  CLASSIC_LIGHT = "Classic Light"
}

export const determineMapStyle = async (
  mapboxTheme: string,
  darkMode: boolean,
  navigationView: boolean,
  satelliteView: boolean
): Promise<any> => {
  if (mapboxTheme?.length > 0) {
    switch (mapboxTheme) {
      case MapStyle.AMERICAN_MEMORY:
        return import('@consider-it/map-styles/src/memory.json').then(x => x.default);
      case MapStyle.BASIC:
        return import('@consider-it/map-styles/src/basic.json').then(x => x.default);
      case MapStyle.BASIC_OVERCAST:
        return import('@consider-it/map-styles/src/overcast.json').then(x => x.default);
      case MapStyle.BLUEPRINT:
        return import('@consider-it/map-styles/src/blueprint.json').then(x => x.default);
      case MapStyle.FRANK:
        return import('@consider-it/map-styles/src/frank.json').then(x => x.default);
      case MapStyle.MINIMO:
        return import('@consider-it/map-styles/src/minimo.json').then(x => x.default);
      case MapStyle.PENCIL:
        return import('@consider-it/map-styles/src/pencil.json').then(x => x.default);
      case MapStyle.STANDARD:
        return import('@consider-it/map-styles/src/standard.json').then(x => x.default);
      case MapStyle.CLASSIC_DARK:
        return import('@consider-it/map-styles/src/v2x_classic_dark.json').then(x => x.default);
      case MapStyle.CLASSIC_LIGHT:
        return import('@consider-it/map-styles/src/v2x_classic_light.json').then(x => x.default);
    }
  }
  if (satelliteView) {
    return import('@consider-it/map-styles/src/satellite.json').then(response => response.default);
  }
  if (darkMode && navigationView) {
    return import('@consider-it/map-styles/src/dark_nav.json').then(response => response.default);
  }
  if (darkMode) {
    return import('@consider-it/map-styles/src/dark.json').then(response => response.default);
  }
  if (navigationView) {
    return import('@consider-it/map-styles/src/light_nav.json').then(response => response.default);
  }
  return import('@consider-it/map-styles/src/light.json').then(response => response.default);
};

export const themeBasedClass = (darkMode: boolean, mapboxTheme: string): string => {
  if (mapboxTheme === MapStyle.SATELLITE) return "satellite-view";
  if (darkMode) return "darkmode";
  return "";
};