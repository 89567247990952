/**
 * @module Sidebar Component
 * @description Responsible for rendering a sidebar menu in the application
 */
import { useSelector, useDispatch } from "react-redux";
import {
  mapInfoActions,
  selectOpenSidebar,
  selectOpenDataView,
} from "../../store/mapInfo";
import { appSettings } from "../../config/AppSettings";
import { motion, AnimatePresence } from "framer-motion";
import { t } from "i18next";

/**
 * SidebarProps
 * @alias SidebarProps
 * @description Props passed to the Map component @see Sidebar
 * @property [children] Ref to the child elements of the sidebar
 */
type SidebarProps = {
  children: React.ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
  /** @var dispatch Dispatch actions to the Redux store, triggers state changes in the application */
  const dispatch = useDispatch();
  /** @var openSidebar State of visibility of the app's sidebar */
  const openSidebar = useSelector(selectOpenSidebar);
  /** @var openDataView State of visibility of the app's data view */
  const openDataView = useSelector(selectOpenDataView);

  /** @var inp Ref to the hamburger menu */
  const inp = document.getElementById("menu-toggle") as HTMLInputElement;

  // If openSidebar is false, set inp.checked to false as well to make sure that the correct icon (for opening and closing) is displayed
  if (inp?.checked && !openSidebar) inp.checked = false;

  const handleSidebar = () => {
    // Set sidebar to the opposite of the current state
    dispatch(mapInfoActions.toggleSidebar());

    // If openDataView is true and openSidebar is false, hide etsi to prevent overlapping in the UI
    if (openDataView && !openSidebar) dispatch(mapInfoActions.hideEtsi());
    else dispatch(mapInfoActions.showEtsi());
  };

  return (
    <>
      <input
        id="menu-toggle"
        data-testid="menu-input"
        type="checkbox"
        onClick={handleSidebar}
      />

      <label
        className="menu-btn"
        data-testid="hamburger-menu"
        htmlFor="menu-toggle"
      >
        <span></span>
      </label>

      <AnimatePresence>
        {openSidebar && (
          <motion.div
            animate={{ x: 0 }}
            transition={{ x: { duration: 0.25 } }}
            initial={{ x: -appSettings.sidebarXAxis }}
            exit={{ x: -appSettings.sidebarXAxis }}
            className="sidebar"
            data-testid="sidebar-menu"
          >
            <div className="version-tag">
              <p>{window.env?.VERSION_TAG ?? ""}</p>
            </div>
            <div className="child-container" data-testid="sidebar">
              <div className="child-items">{children}</div>
            </div>
            <div className="legal">
              <a
                href="https://consider-it.de/impressum/"
                target="_blank"
                rel="noreferrer"
              >
                {t("Impressum")}
              </a>
              <a
                href="https://consider-it.de/datenschutzerklaerung/"
                target="_blank"
                rel="noreferrer"
              >
                {t("Datenschutz")}
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
