import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { appSettings } from "../config/AppSettings";
import { RootState } from ".";

interface IMqttConnectionState {
  mqttHost: string;
  mqttPort: number;
  usesRxRed: boolean;
}

const initialMqttConnectionState: IMqttConnectionState = {
  mqttHost: appSettings.mqttHost,
  mqttPort: appSettings.mqttPort,
  usesRxRed: appSettings.usesRxRed,
};

const mqttConnectionSlice = createSlice({
  name: "mqttConnection",
  initialState: initialMqttConnectionState,
  reducers: {
    connectMqttHost(state, action: PayloadAction<string>) {
      state.mqttHost = action.payload;
    },

    connectMqttPort(state, action: PayloadAction<number>) {
      state.mqttPort = action.payload;
    },

    selectTopicSet(state, action: PayloadAction<boolean>): void {
      state.usesRxRed = action.payload
    }
  },
});

export const selectMqttHost = (state: RootState) =>
  state.mqttConnection.mqttHost;

export const selectMqttPort = (state: RootState) =>
  state.mqttConnection.mqttPort;

export const selectTopicSet = (state: RootState) =>
  state.mqttConnection.usesRxRed

export const mqttConnectionActions = mqttConnectionSlice.actions;
export default mqttConnectionSlice.reducer;
