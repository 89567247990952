/**
 * @module AppSettings
 */
import { MessageTypeToggle } from "../types/MessageTypes";

type IAppSettings = {
  mqttHost: string;
  mqttPort: number;
  mapboxTheme: string;
  usesRxRed: boolean;
  navigationView: boolean;
  darkMode: boolean;
  satelliteView: boolean;
  iotCloudData: boolean;
  toggleMsgTypes: MessageTypeToggle;
  openSidebar: boolean;
  openDataView: boolean;
  sidebarXAxis: number;
  mapPos: {
    lon: number;
    lat: number;
  };
  mapZoom: number;
  defaultEaseDuration: number;
  defaultPitch: number;
  defaultPermissionRequest: number;
  glosaFrequencyMs: number;
  spatemMaxFreqMs: number;
  usesV2xRxWrapper: boolean;
  laneCorridorWidthM: number;
  featureLifetimeMs: number;
  camMaxFreqMs: number;
  approachHeadingTolerance: number;
  usesMatchedLanes: boolean;
  userFriendlyGlosa: boolean;
  debugGlosa: boolean;
  showGlosaOnMap: boolean;
  showObuPathHistory: boolean;
  showConsoleDebug: boolean;
};
/**
 * @typedef {Object} IAppSettings
 * @description The application's configuration options. 
 * @property {string} mqttHost - Host of the MQTT broker the app connects to for receiving V2X messages.
 * @property {number} mqttPort - Port of the MQTT broker the app connects to for receiving V2X messages. 
 * @property {string} mapboxTheme - Style that will be applied to the application's map.
 * @property {boolean} usesRxRed - Whether the application will listen to V2X messages at a reduced update frequency.
 * @property {boolean} darkMode - Whether the appearance of the UI should adapt to a dark environment.
 * @property {boolean} navigationView - Whether the map's camera should adopt a follower perspective instead of a top-down view.
 * @property {boolean} satelliteView - Whether the map should use satellite imagery instead of an abstract map.
 * @property {boolean} iotCloudData - Whether data from the city of Hamburg's IoT Cloud should be displayed.
 * @property {boolean} userFriendlyGlosa - Whether Green-Light-Optimal Speed-Advise data should be processed, so that the .
 * @property {boolean} useMatchedMapemTopology - Whether lanes leading up to intersections should be matched to fit the course of the underlying streets.
 * @property {boolean} toggleMsgTypes.CAMS - Whether CAM data should be rendered on the map.
 * @property {boolean} toggleMsgTypes.DENMS - Whether DENM data should be rendered on the map.
 * @property {boolean} toggleMsgTypes.CPMS - Whether CPM data should be rendered on the map.
 * @property {boolean} toggleMsgTypes.SPATEMS - Whether intesection data should be rendered on the map.
 */
export const appSettings: IAppSettings = {
  mqttHost: "172.25.0.5",
  mqttPort: 9001,
  mapboxTheme: "",
  usesRxRed: false,
  darkMode: false,
  navigationView: false,
  satelliteView: false,
  iotCloudData: false,
  toggleMsgTypes: {
    CAMS: true,
    CPMS: true,
    DENMS: true,
    SPATEMS: true,
    IVIMS: true
  },
  openSidebar: false,
  openDataView: false,
  sidebarXAxis: 500,
  mapPos: {
    lon: 9.93655,
    lat: 53.55076,
  },
  mapZoom: 15,
  defaultEaseDuration: 2000,
  defaultPitch: 50,
  defaultPermissionRequest: 3000,
  glosaFrequencyMs: 600,
  spatemMaxFreqMs: 500,
  usesV2xRxWrapper: true,
  laneCorridorWidthM: 20,
  featureLifetimeMs: 2000,
  camMaxFreqMs: 500,
  approachHeadingTolerance: 30,
  usesMatchedLanes: false,
  userFriendlyGlosa: true,
  debugGlosa: false,
  showGlosaOnMap: false,
  showObuPathHistory: true,
  showConsoleDebug: false,
};
