import CoreSettings from "./CoreSettings";
import MqttSettings from "./MqttSettings";
import ToggleSettings from "./ToggleSettings";
import BugReporting from "./BugReporting";
import { t } from "i18next";

const Settings = () => {
  return (
    <>
      <MqttSettings />
      <ToggleSettings />
      <BugReporting
        placeholder={t("bugInput")}
        setValue={
          () => { return; }
        }
        emptyInputMessage="bugReportEmpty"
      />
      <CoreSettings />
    </>
  );
};

export default Settings;
