import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { appSettings } from "../config/AppSettings";
import { RootState } from ".";

interface IMapInfoState {
    glosaFrequencyMs: number;
    spatemMaxFreqMs: number;
    usesV2xRxWrapper: boolean;
    laneCorridorWidthM: number;
    featureLifetimeMs: number;
    camMaxFreqMs: number;
    approachHeadingTolerance: number;
    usingMatchedLanes: boolean;
    userFriendlyGlosa: boolean;
    debugGlosa: boolean;
    showObuPathHistory: boolean;
    showConsoleDebug: boolean;
}

const initialCoreLibraryState: IMapInfoState = {
    glosaFrequencyMs: appSettings.glosaFrequencyMs,
    spatemMaxFreqMs: appSettings.spatemMaxFreqMs,
    usesV2xRxWrapper: appSettings.usesV2xRxWrapper,
    laneCorridorWidthM: appSettings.laneCorridorWidthM,
    featureLifetimeMs: appSettings.featureLifetimeMs,
    camMaxFreqMs: appSettings.camMaxFreqMs,
    approachHeadingTolerance: appSettings.approachHeadingTolerance,
    usingMatchedLanes: appSettings.usesMatchedLanes,
    userFriendlyGlosa: appSettings.userFriendlyGlosa,
    debugGlosa: appSettings.debugGlosa,
    showObuPathHistory: appSettings.showObuPathHistory,
    showConsoleDebug: appSettings.showConsoleDebug
};

const coreLibrarySlice = createSlice({
    name: "coreLibrary",
    initialState: initialCoreLibraryState,
    reducers: {
        setGlosaFrequency(state, action: PayloadAction<number>) {
            state.glosaFrequencyMs = action.payload;
        },
        setSpatemFrequency(state, action: PayloadAction<number>) {
            state.spatemMaxFreqMs = action.payload;
        },
        toggleUsesV2XWrapper(state) {
            state.usesV2xRxWrapper = !state.usesV2xRxWrapper;
        },
        setLaneCorridorWidth(state, action: PayloadAction<number>) {
            state.laneCorridorWidthM = action.payload;
        },
        setFeatureLifetime(state, action: PayloadAction<number>) {
            state.featureLifetimeMs = action.payload;
        },
        setCamFrequency(state, action: PayloadAction<number>) {
            state.camMaxFreqMs = action.payload;
        },
        setApproachHeadingTolerance(state, action: PayloadAction<number>) {
            state.approachHeadingTolerance = action.payload;
        },
        toggleUsesMatchedLanes(state) {
            state.usingMatchedLanes = !state.usingMatchedLanes;
        },
        toggleUserFriendlyGlosa(state) {
            state.userFriendlyGlosa = !state.userFriendlyGlosa;
        },
        toggleShowDebugGlosa(state) {
            state.debugGlosa = !state.debugGlosa;
        },
        toggleShowObuPathHistory(state) {
            state.showObuPathHistory = !state.showObuPathHistory;
        },
        toggleShowConsoleDebug(state) {
            state.showConsoleDebug = !state.showConsoleDebug;
        },
    },
});

export const selectUsesV2XWrapper = (state: RootState) => state.coreLibrary.usesV2xRxWrapper;
export const selectUsesMatchedLanes = (state: RootState) => state.coreLibrary.usingMatchedLanes;
export const selectUserFriendlyGlosa = (state: RootState) => state.coreLibrary.userFriendlyGlosa;
export const selectShowDebugGlosa = (state: RootState) => state.coreLibrary.debugGlosa;
export const selectShowObuPathHistory = (state: RootState) => state.coreLibrary.showObuPathHistory;
export const selectShowConsoleDebug = (state: RootState) => state.coreLibrary.showConsoleDebug;
export const selectGlosaFrequency = (state: RootState) => state.coreLibrary.glosaFrequencyMs;
export const selectSpatemFrequency = (state: RootState) => state.coreLibrary.spatemMaxFreqMs;
export const selectLaneCorridorWidth = (state: RootState) => state.coreLibrary.laneCorridorWidthM;
export const selectFeatureLifetime = (state: RootState) => state.coreLibrary.featureLifetimeMs;
export const selectCamFrequency = (state: RootState) => state.coreLibrary.camMaxFreqMs;
export const selectApproachHeadingTolerance = (state: RootState) => state.coreLibrary.approachHeadingTolerance;


export const coreLibraryActions = coreLibrarySlice.actions;
export default coreLibrarySlice.reducer;
