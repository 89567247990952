import { useState } from "react";
import { t } from "i18next";

interface MqttSettingsItemProps {
  header?: string;
  type: string;
  placeholder: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  currentValue: string | number;
  checkValidInput: (input: string) => boolean;
  invalidInputMessage: string;
  emptyInputMessage: string;
  testIdPrefix?: string;
}

const MqttSettingsItem = ({
  header,
  type,
  placeholder,
  setValue,
  currentValue,
  checkValidInput,
  invalidInputMessage,
  emptyInputMessage,
  testIdPrefix,
}: MqttSettingsItemProps) => {
  const [input, setInput] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [emptyInput, setEmptyInput] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleChange = () => {
    if (!input) {
      setIsValid(true);
      setEmptyInput(true);
      setInput("");
      return;
    }

    if (checkValidInput(input)) {
      setIsValid(false);
      setEmptyInput(false);
      setInput("");
      return;
    }

    setIsValid(true);
    setEmptyInput(false);
    setValue(input);
    setInput("");
  };

  const handleChangeEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleChange();
    }
  };

  return (
    <>
      {header && <h2>{t(header)}</h2>}
      <div className="input-wrapper">
        <div className="input-field">
          <input
            data-testid={`${testIdPrefix}input`}
            type={type}
            value={input}
            onChange={handleInput}
            onKeyDown={handleChangeEnter}
            placeholder={`${t(placeholder)}`}
          />
          <button onClick={handleChange}>{t("save")}</button>
        </div>
        <p
          data-testid={`${testIdPrefix}current-value`}
          className="current-value"
        >
          {currentValue}
        </p>
        {!isValid && (
          <p data-testid={`${testIdPrefix}invalid-input`} className="error-msg">
            {t(invalidInputMessage)}
          </p>
        )}
        {emptyInput && (
          <p data-testid={`${testIdPrefix}empty-input`} className="error-msg">
            {t(emptyInputMessage)}
          </p>
        )}
      </div>
    </>
  );
};

export default MqttSettingsItem;
