import Map from "./components/ui/Map";
import Sidebar from "./components/ui/Sidebar";
import Settings from "./components/settings/Settings";
import { useState, useEffect } from "react";
import { IV2XProvider, V2XProvider } from "./services/V2XProvider";
import Notification from "./components/ui/Notification";
import { onColorSchemeChange } from "./helper/colorScheme";
import { useSelector } from "react-redux";
import { selectDarkMode, selectSatelliteView } from "./store/mapView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { themeBasedClass } from "./helper/colorScheme";
import { selectCoreSettings } from "./store";
import * as Sentry from "@sentry/react";


if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
}

const App = () => {
  const [v2xService, setV2xService] = useState<IV2XProvider>();
  const darkMode = useSelector(selectDarkMode);
  const satelliteView = useSelector(selectSatelliteView);
  const coreSettings = useSelector(selectCoreSettings);
  const [mqttConnected, setMqttConnected] = useState(false);

  useEffect(() => {
    const v2x = new V2XProvider(coreSettings);
    v2x.onConnectionStateChange(setMqttConnected);
    setV2xService(v2x);
    onColorSchemeChange(() => darkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    v2xService?.updateSettings(coreSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreSettings]);

  return (
    <div data-testid="app" className={themeBasedClass(darkMode, satelliteView)}>
      {v2xService && (
        <>
          <Map v2xService={v2xService} />
          <Notification v2xProvider={v2xService} />
        </>
      )}

      <Sidebar>
        <Settings />
      </Sidebar>

      <div
        className="connectivity"
        data-testid="connectivity"
        style={{ visibility: mqttConnected ? "hidden" : "visible" }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="fa-2x"
          data-testid="connectivity-icon"
        />
      </div>
    </div>
  );
};

export default App;
