import { useSelector, useDispatch } from "react-redux";
import InputSettingsItem from "./InputSettingsItem";
import { regexIsInt } from "../../helper/regex";
import { selectGlosaFrequency, selectApproachHeadingTolerance, selectCamFrequency, selectFeatureLifetime, selectLaneCorridorWidth, selectShowDebugGlosa, selectSpatemFrequency, selectUserFriendlyGlosa, selectUsesMatchedLanes, coreLibraryActions, selectShowConsoleDebug, selectShowObuPathHistory } from "../../store/coreLibrary";
import { t } from "i18next";
import ToggleSettingsItem from "./ToggleSettingsItem";
import { appSettings } from "../../config/AppSettings";

const CoreSettings = () => {
  const dispatch = useDispatch();
  const glosaFrequencyMs = useSelector(selectGlosaFrequency);
  const spatemMaxFreqMs = useSelector(selectSpatemFrequency);
  const usesMatchedLanes = useSelector(selectUsesMatchedLanes);
  const laneCorridorWidthM = useSelector(selectLaneCorridorWidth);
  const featureLifetimeMs = useSelector(selectFeatureLifetime);
  const camMaxFreqMs = useSelector(selectCamFrequency);
  const approachHeadingTolerance = useSelector(selectApproachHeadingTolerance);
  const userFriendlyGlosa = useSelector(selectUserFriendlyGlosa);
  const debugGlosa = useSelector(selectShowDebugGlosa);
  const showObuPathHistory = useSelector(selectShowObuPathHistory);
  const showConsoleDebug = useSelector(selectShowConsoleDebug);

  return (
    <>
      <h1 className="settings-header">{t("DeveloperSettings")}</h1>
      <InputSettingsItem
        type="number"
        placeholder="GlosaFreqInput"
        currentValue={glosaFrequencyMs}
        setValue={(frequency: number) =>
          dispatch(coreLibraryActions.setGlosaFrequency(frequency))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="integerInvalid"
        emptyInputMessage="integerInvalid"
        testIdPrefix="glosa-freq-"
      />
      <InputSettingsItem
        type="number"
        placeholder="SpatemFreqInput"
        currentValue={spatemMaxFreqMs}
        setValue={(frequency: number) =>
          dispatch(coreLibraryActions.setSpatemFrequency(frequency))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="integerInvalid"
        emptyInputMessage="integerInvalid"
        testIdPrefix="spatem-freq-"
      />
      <InputSettingsItem
        type="number"
        placeholder="CamFreqInput"
        currentValue={camMaxFreqMs}
        setValue={(frequency: number) =>
          dispatch(coreLibraryActions.setCamFrequency(frequency))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="integerInvalid"
        emptyInputMessage="integerInvalid"
        testIdPrefix="cam-freq-"
      />
      <InputSettingsItem
        type="number"
        placeholder="LaneCorridorWidthInput"
        currentValue={laneCorridorWidthM}
        setValue={(width: number) =>
          dispatch(coreLibraryActions.setLaneCorridorWidth(width))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="integerInvalid"
        emptyInputMessage="integerInvalid"
        testIdPrefix="lanewidth-"
      />
      <InputSettingsItem
        type="number"
        placeholder="FeatureTTLInput"
        currentValue={featureLifetimeMs}
        setValue={(ttl: number) =>
          dispatch(coreLibraryActions.setFeatureLifetime(ttl))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="integerInvalid"
        emptyInputMessage="integerInvalid"
        testIdPrefix="featurettl-"
      />
      <InputSettingsItem
        type="number"
        placeholder="ApproachHeadingInput"
        currentValue={approachHeadingTolerance}
        setValue={(degrees: number) =>
          dispatch(coreLibraryActions.setApproachHeadingTolerance(degrees))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="integerInvalid"
        emptyInputMessage="integerInvalid"
        testIdPrefix="approach-"
      />
      <ToggleSettingsItem
        header={"UsingMatched"}
        onChange={() =>
          dispatch(coreLibraryActions.toggleUsesMatchedLanes())
        }
        checked={usesMatchedLanes ?? appSettings.usesMatchedLanes}
      />
      <ToggleSettingsItem
        header={"UserFriendlyGLOSA"}
        onChange={() =>
          dispatch(coreLibraryActions.toggleUserFriendlyGlosa())
        }
        checked={userFriendlyGlosa ?? appSettings.userFriendlyGlosa}
      />
      <ToggleSettingsItem
        header={"ShowDebugGlosa"}
        onChange={() =>
          dispatch(coreLibraryActions.toggleShowDebugGlosa())
        }
        checked={debugGlosa ?? appSettings.debugGlosa}
      />
      <ToggleSettingsItem
        header={"ShowObuPathHistory"}
        onChange={() =>
          dispatch(coreLibraryActions.toggleShowObuPathHistory())
        }
        checked={showObuPathHistory ?? appSettings.showObuPathHistory}
      />
      <ToggleSettingsItem
        header={"ShowConsoleDebug"}
        onChange={() =>
          dispatch(coreLibraryActions.toggleShowConsoleDebug())
        }
        checked={showConsoleDebug ?? appSettings.showConsoleDebug}
      />
    </>
  );
};

export default CoreSettings;
