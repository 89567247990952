/**
 * @module Notification Component
 * @description Provides a notification container div that contains DENM warning messages and GLOSA information
 */
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOpenDataView, selectOpenSidebar } from "../../store/mapInfo";
import { selectToggleMsgTypes } from "../../store/v2xMessage";
import { motion, AnimatePresence } from "framer-motion";
import { appSettings } from "../../config/AppSettings";
import { IV2XProvider, WarningDenm } from "../../services/V2XProvider";
import SpatContainer from "./SpatContainer";
import DenmContainer from "./DenmContainer";
import { TlSpat } from "../../types/CoreWorker";

/**
 * @var Showable
 * @enum {number}
 * @description Describes the notification type
 */
enum Showable {
  Denm,
  Glosa,
  Any,
}

type NotificationProps = {
  v2xProvider: IV2XProvider;
};
/**
 * NotificationProps
 * @typedef {Object} NotificationProps
 * @description Props passed to the Notification component
 * @property {IV2XProvider} v2xService - provider of incoming V2X data that should be displayed
 */
/**
 * Notification
 * @alias Notification
 * @description Functional React component providing the notification component div
 * @param {NotificationProps} - props passed to the Notification component
 * @see NotificationProps
 */
const Notification = ({ v2xProvider }: NotificationProps) => {
  /**
   * @var glosa
   * @description State of glosa information to be displayed as a notification
   */
  const [glosa, setGlosa] = useState<TlSpat[]>([]);
  /**
   * @var denmWarning
   * @description State of glosa information to be displayed as a notification
   */
  const [denmWarning, setDenmWarning] = useState<WarningDenm[]>([]);
  /**
   * @var cancelTimer
   * @description Timer object for auto-hinding notification
   */
  const [cancelTimer, setCancelTimer] = useState<NodeJS.Timer | number>();
  /**
   * @var openSidebar
   * @description State indicating whether the sidebar is open
   */
  const openSidebar = useSelector(selectOpenSidebar);
  /**
   * @var openDataView
   * @description State indicating whether the dataview is open
   */
  const openDataView = useSelector(selectOpenDataView);
  /**
   * @var toggleMsgTypes
   * @description State indicating which message types are active in the settings
   */
  const toggleMsgTypes = useSelector(selectToggleMsgTypes);

  useEffect(() => {
    v2xProvider.onGlosa(setGlosa);
    v2xProvider.onDenmWarning((denm) =>
      setDenmWarning(denm.filter((d) => d.causeCode !== 0))
    );
  }, [v2xProvider, setGlosa, setDenmWarning]);

  // effect loop to clear notifications once no V2X messages come in
  // runs whenever a V2X message relevant for the notification component comes in
  useEffect(() => {
    if (cancelTimer) clearTimeout(cancelTimer);
    setCancelTimer(
      setTimeout(() => {
        setGlosa([]);
        setDenmWarning([]);
      }, 2000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glosa, denmWarning, setCancelTimer]);

  /**
   * @description determines whether and which type of notification should be displayed
   * @param {Showable} type Type of notification intended to be shown
   * @returns {boolean} whether a notification of the given type should be shown
   */
  const showShowable = useCallback(
    (type: Showable): boolean => {
      const showDenm =
        type !== Showable.Glosa
          ? denmWarning.length > 0 &&
          // Do not show DENM warnings if sidebar or dataview are open on a mobile device
          !(window.innerWidth < 900 && (openSidebar || openDataView)) &&
          // Do not show DENM warnings if DENMs are turned off in the settings
          toggleMsgTypes.DENMS
          : false;
      const showGlosa =
        !showDenm && // Do not show glosa if a DENM is shown
        (type !== Showable.Denm
          ? glosa.length > 0 &&
          // Do not show glosa if sidebar or dataview are open on a mobile device
          !(window.innerWidth < 900 && (openSidebar || openDataView)) &&
          // Do not show GLOSA if SPATEMs are turned off in the settings
          toggleMsgTypes.SPATEMS
          : false);
      return showGlosa || showDenm;
    },
    [openSidebar, openDataView, denmWarning, glosa, toggleMsgTypes]
  );

  return (
    <>
      {<AnimatePresence>
        {showShowable(Showable.Any) && (
          <motion.div
            animate={
              (openSidebar || openDataView) && window.innerWidth > 900
                ? { height: 150, opacity: 1, x: appSettings.sidebarXAxis / 2 }
                : { height: 150, opacity: 1 }
            }
            transition={{ duration: 0.1, x: { duration: 0.25 } }}
            initial={{ height: 0, opacity: 0 }}
            exit={{ height: 0, opacity: 0 }}
            className="notification-container"
            data-testid="notification-container"
          >
            {showShowable(Showable.Glosa) &&
              glosa.map((spat, i) => {
                return <SpatContainer spat={spat} key={i} />;
              })}
            {showShowable(Showable.Denm) &&
              denmWarning.map((denm, i) => {
                return <DenmContainer denm={denm} key={i} />;
              })}
          </motion.div>
        )}
      </AnimatePresence>
      }
    </>
  );
};

export default Notification;
