import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mapViewActions } from "../../store/mapView";
import Select, { SingleValue } from "react-select";
import { MapStyle } from "../../styles/mapStyle";
import BASIC_OVERCAST from "../../assets/basic_overcast.png";
import BASIC from "../../assets/basic.png";
import AMERICAN_MEMORY from "../../assets/american_memory.png";
import PENCIL from "../../assets/pencil.png";
import FRANK from "../../assets/frank.png";
import BLUEPRINT from "../../assets/blueprint.png";
import STANDARD from "../../assets/standard.png";
import MINIMO from "../../assets/minimo.png";
import CLASSIC_DARK_IMG from "../../assets/classic_dark_screenshot.png";
import CLASSIC_LIGHT_IMG from "../../assets/classic_light_screenshot.png";
import { t } from "i18next";
import { selectUsesMatchedLanes } from "../../store/coreLibrary";

type OptionType = {
  value: string;
  label: string;
  image: string;
};

const options: OptionType[] = [
  { value: MapStyle.BASIC_OVERCAST, label: MapStyle.BASIC_OVERCAST, image: BASIC_OVERCAST },
  { value: MapStyle.BASIC, label: MapStyle.BASIC, image: BASIC },
  { value: MapStyle.AMERICAN_MEMORY, label: MapStyle.AMERICAN_MEMORY, image: AMERICAN_MEMORY },
  { value: MapStyle.PENCIL, label: MapStyle.PENCIL, image: PENCIL },
  { value: MapStyle.FRANK, label: MapStyle.FRANK, image: FRANK },
  { value: MapStyle.BLUEPRINT, label: MapStyle.BLUEPRINT, image: BLUEPRINT },
  { value: MapStyle.STANDARD, label: MapStyle.STANDARD, image: STANDARD },
  { value: MapStyle.MINIMO, label: MapStyle.MINIMO, image: MINIMO },
  { value: MapStyle.CLASSIC_DARK, label: MapStyle.CLASSIC_DARK, image: CLASSIC_DARK_IMG },
  { value: MapStyle.CLASSIC_LIGHT, label: MapStyle.CLASSIC_LIGHT, image: CLASSIC_LIGHT_IMG },
];

const DropDownSettings = () => {
  const [selectedOption, setSelectedOption] = useState<SingleValue<OptionType>>(
    JSON.parse(localStorage.getItem("mapStyle")!) || null
  );
  const dispatch = useDispatch();
  const usingMatched = useSelector(selectUsesMatchedLanes)

  useEffect(() => {
    if (selectedOption != null) {
      dispatch(mapViewActions.mapboxTheme(selectedOption.value));
    } else {
      dispatch(mapViewActions.mapboxTheme(""));
    }
  }, [selectedOption, dispatch]);

  useEffect(() => {
    localStorage.setItem("mapStyle", JSON.stringify(selectedOption));
  }, [selectedOption]);

  return (
    <Select
      data-testid="map-style-dropdown"
      className="basic-single"
      classNamePrefix="dropdown"
      placeholder={usingMatched ? t("matchedSelectorHint") : t("SelectMapStyle")}
      onChange={(e: any) => setSelectedOption(e)}
      options={options}
      value={selectedOption}
      isDisabled={usingMatched}
      isClearable={true}
      isSearchable={false}
      classNames={{ clearIndicator: () => "reset-map-style" }}
      formatOptionLabel={(map) => (
        <div className="theme-options">
          <img src={map.image} alt={map.label} />
          <span>{map.label}</span>
        </div>
      )}
    />
  );
};

export default DropDownSettings;
