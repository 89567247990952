import Switch from "react-switch";
import { t } from "i18next";


interface ToggleSettingsItemProps {
  header: string;
  onChange: () => void;
  checked: boolean;
  disabled?: boolean;
  className?: string;
}

const ToggleSettingsItem = ({
  header,
  onChange,
  checked,
  disabled,
  className,
}: ToggleSettingsItemProps) => {
  return (
    <div className="toggle-settings" data-testid="toggle-settings">
      <h2>{t(header)}</h2>
      <Switch
        className={className}
        onChange={onChange}
        checked={!!checked}
        onColor={"#E5690C"}
        uncheckedIcon={false}
        checkedIcon={false}
        disabled={disabled}
      />
    </div>
  );
};

export default ToggleSettingsItem;
