/**
 * @module GeojsonSource
 * @description The GeoJSON sources that are displayed on the application's map. Usually, each source handles the display of one particular V2X message type. 
 */

/**
 * @var GeojsonSource
 * @enum {number}
 * @description The currently implemented GeoJSON sources that will be displayed on the application's map.
 */
export enum GeojsonSource {
  /** 
   * @member GREEN_LANES
   * @description Displays the course of a lane leading up to a green traffic light as a green line on the map. 
   */
  GREEN_LANES,
  /** 
   * @member RED_LANES
   * @description Displays the course of a lane leading up to a red traffic light as a red line on the map. 
   */
  RED_LANES,
  /** 
   * @member YELLOW_LANES
   * @description Displays the course of a lane leading up to a yellow traffic light as a yellow line on the map. 
   */
  YELLOW_LANES,
  /** 
   * @member DARK_LANES
   * @description Displays the course of a lane leading up to a traffic light with unknown signal information as a grey line on the map. 
   */
  DARK_LANES,
  /** 
   * @member INTERSECTION_REFPT
   * @description Displays the position of the an intersections road-side unit as an icon on the map.
   */
  INTERSECTION_REFPT,
  STATIC_LANES,
  /** 
 * @member DENM
 * @description Displays the position of a received DENM on-board unit or the application device as an icon on the map. Displays the trace of a DENM as a red line on the map.
 */
  DENM,
  /** 
   * @member CAM
   * @description Displays the position of a received CAM on-board unit or the application device as an icon on the map. Displays the trace of a CAM as a line on the map.
   */
  CAM,
  /** 
   * @member CPM
   * @description Displays the outline or location of a detected object, displays the detection area of the sensor(s).
   */
  CPM,
  /** 
  * @member GLOSA_DEBUG
  * @description Displays the intersection area and the approach corridor the GLOSA algorithm has determined to be matching as polygons on the map.
  */
  GLOSA_DEBUG,
  /** 
  * @member TL_CONTROL
  * @description Displays the request-response cycles in SREM/SSEM communication between V2X stations as line graphs between the station locations.
  */
  TL_CONTROL,
  /** 
  * @member MAP_GLOSA
  * @description Displays GLOSA traffic light symbols directly on the map at the location of the approach stopline.
  */
  MAP_GLOSA,
  /** 
 * @member OBU_PATH
 * @description Displays the path history of the connected on-board unit or the application device as an icon on the map.
 */
  OBU_PATH,
  /** 
   * @member IVIM
   * @description Displays the reference position of IVI messages as well as relevant lanes and zones on the map.
   */
  IVIM,
  /** 
   * @member IOT_CLOUD_DATA
   * @description Displays the location of StadtRad bike-sharing stations and EV chargers as received from the city of Hamburg's IoT Cloud as icons on the map.
   */
  IOT_CLOUD_DATA,
  /** 
 * @member OBU_POS
 * @description Displays the position of the connected on-board unit or the application device as an icon on the map.
 */
  OBU_POS,
}

/**
 * @description Provides the source ID as defined in the style's JSON specification
 * @param {GeojsonSource} source
 * @returns {string} the ID of the given source
 */
export function id(source: GeojsonSource): string {
  switch (source) {
    case GeojsonSource.CAM:
      return "cam-src"
    case GeojsonSource.OBU_POS:
      return "obu-src"
    case GeojsonSource.OBU_PATH:
      return "obu-path-src"
    case GeojsonSource.DENM:
      return "denm-src"
    case GeojsonSource.GREEN_LANES:
      return "green-lane-src"
    case GeojsonSource.RED_LANES:
      return "red-lane-src"
    case GeojsonSource.YELLOW_LANES:
      return "yellow-lane-src"
    case GeojsonSource.INTERSECTION_REFPT:
      return "ref-point-src"
    case GeojsonSource.DARK_LANES:
      return "dark-lane-src"
    case GeojsonSource.CPM:
      return "cpm-src"
    case GeojsonSource.IOT_CLOUD_DATA:
      return "hh-iot-src"
    case GeojsonSource.TL_CONTROL:
      return "tl-control-src"
    case GeojsonSource.MAP_GLOSA:
      return "map-glosa-src"
    case GeojsonSource.IVIM:
      return "ivim-src"
    case GeojsonSource.GLOSA_DEBUG:
      return "misc-src"
  }
  throw Error("Unreachable!") // Unreachable as all enum cases are covered
}