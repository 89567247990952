/**
 * @module LegendItem Component
 * @description Displays a legend for detected CPM message types
 */
import { t } from "i18next";

type LegendItemProps = {
  msgType: string;
}
/**
 * LegendItemProps
 * @typedef {Object} LegendItemProps
 * @description Props passed to the LegendItem component 
 * @see LegendItem
 * @property {string} msgType - Source of detected message type
 */
/**
 * LegendItem
 * @alias LegendItem
 * @description Functional React component that displays a single cpm type description in the CPM legend 
 * @param {LegendItemProps} legendItemProps - props defining the displayed CPM legend item
 * @see LegendItemProps
 */
const LegendItem = ({ msgType }: LegendItemProps) => {
  return (
    <div className="legend-item">
      <img
        className="legend-img"
        src={`assets/cpm_${msgType}.png`}
        alt={msgType}
      />
      <p data-testid="msg-type">{t(msgType)}</p>
    </div>
  );
};

export default LegendItem;
