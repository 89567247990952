import { WasmCoreSettings } from "@consider-it/v2x-wasm-core";
import { GeojsonSource } from "./GeojsonSource";


/**
 * ObuInfo
 * @typedef {Object} ObuInfo
 * @description Describes the state of a connected on-board unit (OBU)
 * @property {number} lat - latitude of the OBU position in degrees WGS84
 * @property {number} lon - longitude of the OBU position in degrees WGS84
 * @property {number} heading - heading of the OBU in degrees from zero north
 * @property {number} kphSpeed - speed of the OBU in km per hour
 * @property {number} time - last timestamp of the OBU's system clock
 */
export type ObuInfo = {
  lat: number;
  lon: number;
  heading: number;
  kphSpeed: number;
  time: number;
};

/**
 * @var MovementPhaseState
 * @enum {number}
 * @description Representation of the movement phase state of a traffic light according to CEN ISO/TS 19091-2018.
 */
export enum MovementPhaseState {
  /** 
   * @member UNAVAILABLE
   */
  UNAVAILABLE = 0,
  /** 
   * @member DARK
   * @description The respective signal is dark
   */
  DARK = 1,
  /** 
   * @member STOP_THEN_PROCEED
   * @description The respective signal is red
   */
  STOP_THEN_PROCEED = 2,
  /** 
   * @member STOP_AND_REMAIN
   * @description The respective signal is red
   */
  STOP_AND_REMAIN = 3,
  /** 
   * @member PRE_MOVEMENT
   * @description The respective signal is red-yellow
   */
  PRE_MOVEMENT = 4,
  /** 
   * @member PERMISSIVE_MOVEMENT_ALLOWED
   * @description The respective signal is green
   */
  PERMISSIVE_MOVEMENT_ALLOWED = 5,
  /** 
   * @member PROTECTED_MOVEMENT_ALLOWED
   * @description The respective signal is green
   */
  PROTECTED_MOVEMENT_ALLOWED = 6,
  /** 
   * @member PERMISSIVE_CLEARANCE
   * @description The respective signal is yellow
   */
  PERMISSIVE_CLEARANCE = 7,
  /** 
   * @member PROTECTED_CLEARANCE
   * @description The respective signal is yellow
   */
  PROTECTED_CLEARANCE = 8,
  /** 
   * @member CAUTION_CONFLICTING_TRAFFIC
   * @description The respective signal is flashing yellow
   */
  CAUTION_CONFLICTING_TRAFFIC = 9,
}

/**
 * @var Maneuver
 * @enum {number}
 * @description Simplified description of a traffic light signal's "direction", i.e. what kind of symbol the signal displays.
 */
export enum Maneuver {
  /** 
   * @member OMNI
   * @description The signal displays a fully-lit, round "omni" symbol
   */
  OMNI,
  /** 
   * @member LEFT
   * @description The signal displays an arrow symbol pointing left
   */
  LEFT,
  /** 
   * @member RIGHT
   * @description The signal displays an arrow symbol pointing right
   */
  RIGHT,
  /** 
   * @member STRAIGHT
   * @description The signal displays an arrow symbol pointing up
   */
  STRAIGHT,
  /** 
   * @member MISC
   * @description Fallback option in case the maneuver is not known
   */
  MISC,
}

/**
 * TlSpat
 * @typedef {Object} TlSpat
 * @description Describes the state of a traffic light signal. Usually received as part of SPaT messages.
 * @property {Maneuver} direction - simplified description of a traffic light signal's "direction", i.e. what kind of symbol the signal displays.
 * @property {MovementPhaseState} phase - representation of the movement phase state of a traffic light according to CEN ISO/TS 19091-2018
 * @property {number} remainingSec - remaining seconds until the next signal switch event
 * @property {number} advisedSpeedKph - appropriate speed in kilometers per hour with respect to the signal switch times of the traffic light that the OBU is approaching
 */
export type TlSpat = {
  direction: Maneuver;
  phase: MovementPhaseState;
  remainingSec: number | undefined;
  advisedSpeedKph: number | undefined;
};

export enum WorkerRequestType {
  INIT = "init",
  LATEST_MESSAGE = "latest-message",
  UPDATE_SETTINGS = "update-settings"
}

export enum WorkerResponseType {
  OBU_INFO = "on-obu-info",
  GEOJSON = "on-geojson",
  ERROR = "error",
  CONNECTED = "on-connect",
  GLOSA = "glosa",
  RAW_MSG = "raw-message",
  MATCHED_LANE_PHASES = "matched-lane-phases",
  LOOPBACK = "loopback"
}

export interface WorkerResponse {
  type: WorkerResponseType;
  obuInfo?: ObuInfo;
  json?: string;
  src?: GeojsonSource;
  error?: Error;
  glosa?: TlSpat[];
  rawMsg?: string;
  greenLaneUuids?: string;
  yellowLaneUuids?: string;
  redLaneUuids?: string;
  darkLaneUuids?: string;
}


export interface WorkerRequest {
  type: WorkerRequestType;
  settings?: CoreSettings;
  obuInfo?: ObuInfo;
  stationId?: number;
  secondaryId?: number;
  messageType?: SubscribedEtsiMsg;
}

export enum SubscribedEtsiMsg {
  DENM = 1,
  CAM = 2,
  SPATEM = 4,
  IVIM = 8,
  MAPEM = 5,
  CPM = 14,
}

/**
 * CoreSettings
 * @typedef {Object} CoreSettings
 * @description Composite settings object defining the configuration of MQTT client, Core worker thread, and the WASM core library
 * @param {string} brokerUrl URL of the MQTT broker to subscribe to. Should include protocol prefix and port number. E.g. `tcp://test.mosquitto.org:1883`
 * @param {boolean} usesRateReducedTopics whether the MQTT broker should subscribe to the rate-reduced variants of the v2x/rx topics instead of the standard ones
 * @param {number} glosaFrequencyMs frequency at which GLOSA information shall be queried from WASM core
 * @param {number} spatemMaxFreqMs maximum frequency of SPATEM updates. Incoming SPATEM messages within the time frame defined using spatemMaxFreqMs will be ignored
 * @param {boolean} usesV2xRxWrapper whether the application should expect the incoming MQTT messages to contain a wrapper according to the consider it V2X RX protocol
 * @param {number} laneCorridorWidthM the width of the corridor computed from a MAPEM's approach (used in WASM core's GLOSA algorithm)
 * @param {number} featureLifetimeMs the default lifetime of a GeoJSON feature in the WASM core cache. E.g. with a featureLifetimeMs value of 2000, a representation of an incoming CAM-message will be displayed for 2 seconds on the map
 * @param {number} camMaxFreqMs maximum frequency of CAM updates. Incoming CAM messages within the time frame defined using spatemMaxFreqMs will be ignored
 * @param {number} approachHeadingTolerance heading tolerance in degrees of the GLOSA algorithm when determining the current approach. The GLOSA algorithm selects the closest approach to the OBU's current position that is within a heading range of the OBU
 * @param {boolean} usingMatchedLanes whether MAPEM lane geometries should be matched to the coordinates of the corresponding lanes in the OpenStreetMap data set. Matching allows fitting the lane phases into the rendered lanes on the map.
 * @param {boolean} userFriendlyGlosa whether GLOSA information should be processed to remove duplicate icons and confusing timing information
 * @param {boolean} debugGlosa whether intersection areas and lane corridors used by the GLOSA algorithm should be displayed on the map
 * @param {boolean} showObuPathHistory whether the path history of the OBU should be drawn to the map
 * @param {boolean} showConsoleDebug whether the logging output of the core library should be logged to the browser console
 */
export type CoreSettings = {
  brokerUrl: string,
  usesRateReducedTopics: boolean,
  glosaFrequencyMs: number,
  spatemMaxFreqMs: number,
  usesV2xRxWrapper: boolean,
  laneCorridorWidthM: number,
  featureLifetimeMs: number,
  camMaxFreqMs: number,
  approachHeadingTolerance: number,
  usingMatchedLanes: boolean,
  userFriendlyGlosa: boolean,
  debugGlosa: boolean,
  showObuPathHistory: boolean,
  showConsoleDebug: boolean,
};

/**
 * @description extracts a WASM core configuration from a CoreSettings object
 * @param {CoreSettings} settings the CoreSettings used as a basis for the WASM core configuration
 * @returns {WasmCoreSettings} the resulting WASM core configuration
 */
export function toWasmCoreSettings(settings: CoreSettings): WasmCoreSettings {
  return new WasmCoreSettings(
    settings.spatemMaxFreqMs,
    settings.usesV2xRxWrapper,
    settings.laneCorridorWidthM,
    settings.featureLifetimeMs,
    settings.camMaxFreqMs,
    settings.approachHeadingTolerance,
    settings.usingMatchedLanes,
    false, // We do not have easy access to the system time in WASM
    settings.userFriendlyGlosa,
    settings.debugGlosa,
    false, // We do not support Map GLOSA currently
    settings.showObuPathHistory,
    settings.showConsoleDebug,
  );
}