import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDarkMode, selectSatelliteView } from "../../store/mapView";
import TL_LEFT_GREEN_DARK from "../../assets/tl_left_green_xl_dark.png";
import TL_LEFT_GREEN from "../../assets/tl_left_green_xl.png";
import TL_LEFT_RED_DARK from "../../assets/tl_left_red_xl_dark.png";
import TL_LEFT_RED from "../../assets/tl_left_red_xl.png";
import TL_LEFT_YELLOW_DARK from "../../assets/tl_left_yellow_xl_dark.png";
import TL_LEFT_YELLOW from "../../assets/tl_left_yellow_xl.png";
import TL_LEFT_REDYELLOW_DARK from "../../assets/tl_left_redyellow_xl_dark.png";
import TL_LEFT_REDYELLOW from "../../assets/tl_left_redyellow_xl.png";
import TL_OMNI_GREEN_DARK from "../../assets/tl_omni_green_xl_dark.png";
import TL_OMNI_GREEN from "../../assets/tl_omni_green_xl.png";
import TL_OMNI_OFF_DARK from "../../assets/tl_omni_off_xl_dark.png";
import TL_OMNI_OFF from "../../assets/tl_omni_off_xl.png";
import TL_OMNI_RED_DARK from "../../assets/tl_omni_red_xl_dark.png";
import TL_OMNI_RED from "../../assets/tl_omni_red_xl.png";
import TL_OMNI_YELLOW_DARK from "../../assets/tl_omni_yellow_xl_dark.png";
import TL_OMNI_YELLOW from "../../assets/tl_omni_yellow_xl.png";
import TL_OMNI_REDYELLOW_DARK from "../../assets/tl_omni_redyellow_xl_dark.png";
import TL_OMNI_REDYELLOW from "../../assets/tl_omni_redyellow_xl.png";
import TL_RIGHT_GREEN_DARK from "../../assets/tl_right_green_xl_dark.png";
import TL_RIGHT_GREEN from "../../assets/tl_right_green_xl.png";
import TL_RIGHT_RED_DARK from "../../assets/tl_right_red_xl_dark.png";
import TL_RIGHT_RED from "../../assets/tl_right_red_xl.png";
import TL_RIGHT_YELLOW_DARK from "../../assets/tl_right_yellow_xl_dark.png";
import TL_RIGHT_YELLOW from "../../assets/tl_right_yellow_xl.png";
import TL_RIGHT_REDYELLOW_DARK from "../../assets/tl_right_redyellow_xl_dark.png";
import TL_RIGHT_REDYELLOW from "../../assets/tl_right_redyellow_xl.png";
import { TlSpat } from "../../types/CoreWorker";

type SpatContainerProps = {
  spat: TlSpat;
}

const SpatContainer = ({ spat }: SpatContainerProps) => {
  const [label, setLabel] = useState<string>();
  const darkMode = useSelector(selectDarkMode);
  const satelliteView = useSelector(selectSatelliteView);

  const selectValueToDisplay = useCallback(() => {
    // Due to lacking UX research, we do not currently display advised speeds
    // if (spat.advisedSpeedKph !== undefined && spat.advisedSpeedKph > 0 && spat.advisedSpeedKph <= 120.0) {
    //   setLabel(`${spat.advisedSpeedKph} km/h`);
    /*} else */if (spat.remainingSec !== undefined && spat.remainingSec > 0 && spat.remainingSec <= 300.0) {
      setLabel(`${spat.remainingSec} s`);
    } else {
      setLabel("   ");
    }
  }, [spat]);

  useEffect(selectValueToDisplay, [spat, selectValueToDisplay]);

  return (
    <div className="spat-container" data-testid="spat-container">
      <img
        data-testid="spat-image"
        src={getSpatImage(spat, darkMode && !satelliteView)}
        alt="traffic-light"
      />
      <p data-testid="spat-label">{label}</p>
    </div>
  );
};

const getSpatImage = (tlSpat: TlSpat, darkmode: boolean): string => {
  if (darkmode) {
    switch (tlSpat.phase) {
      case 5:
      case 6:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_GREEN_DARK;
          case 2:
            return TL_RIGHT_GREEN_DARK;
          case 0:
          case 3:
            return TL_OMNI_GREEN_DARK;
        }
        break;
      case 2:
      case 3:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_RED_DARK;
          case 2:
            return TL_RIGHT_RED_DARK;
          case 0:
          case 3:
            return TL_OMNI_RED_DARK;
        }
        break;
      case 4:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_REDYELLOW_DARK;
          case 2:
            return TL_RIGHT_REDYELLOW_DARK;
          case 0:
          case 3:
            return TL_OMNI_REDYELLOW_DARK;
        }
        break;
      case 7:
      case 8:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_YELLOW_DARK;
          case 2:
            return TL_RIGHT_YELLOW_DARK;
          case 0:
          case 3:
            return TL_OMNI_YELLOW_DARK;
        }
        break;
    }
    return TL_OMNI_OFF_DARK;
  } else {
    switch (tlSpat.phase) {
      case 5:
      case 6:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_GREEN;
          case 2:
            return TL_RIGHT_GREEN;
          case 0:
          case 3:
            return TL_OMNI_GREEN;
        }
        break;
      case 2:
      case 3:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_RED;
          case 2:
            return TL_RIGHT_RED;
          case 0:
          case 3:
            return TL_OMNI_RED;
        }
        break;
      case 4:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_REDYELLOW;
          case 2:
            return TL_RIGHT_REDYELLOW;
          case 0:
          case 3:
            return TL_OMNI_REDYELLOW;
        }
        break;
      case 7:
      case 8:
        switch (tlSpat.direction) {
          case 1:
            return TL_LEFT_YELLOW;
          case 2:
            return TL_RIGHT_YELLOW;
          case 0:
          case 3:
            return TL_OMNI_YELLOW;
        }
        break;
    }
    return TL_OMNI_OFF;
  }
};

export default SpatContainer;
