import { point } from "@turf/helpers";
import { useEffect, useState } from "react";
import { ObuInfo } from "../types/CoreWorker";

type AnimatedObuInfo = {
  obuInfo: ObuInfo;
  jsTimestamp: number;
};

const toObuInfoFeature = (obuInfo: ObuInfo): GeoJSON.Feature =>
  point([obuInfo.lon, obuInfo.lat], {
    kphSpeed: obuInfo.kphSpeed,
    heading: obuInfo.heading,
  });

export const useAnimatedPosition = (): [
  GeoJSON.Feature,
  React.Dispatch<React.SetStateAction<ObuInfo>>
] => {
  const [obuInfo, setObuInfo] = useState<ObuInfo>({
    lat: 53.550748669886566,
    lon: 9.936546820994705,
    kphSpeed: 0,
    heading: 0,
    time: Date.now(),
  });
  const [animationInfo, setAnimationInfo] =
    useState<[AnimatedObuInfo, AnimatedObuInfo]>();
  const [requestId, setRequestId] = useState<number>();
  const [animatedPosition, setAnimatedPosition] = useState<GeoJSON.Feature>(
    toObuInfoFeature(obuInfo)
  );

  useEffect(() => {
    setAnimatedPosition(
      point([obuInfo.lon, obuInfo.lat], {
        heading: obuInfo.heading,
        kphSpeed: obuInfo.kphSpeed,
      })
    );
  }, [obuInfo]);

  useEffect(() => {
    if (requestId != null) {
      cancelAnimationFrame(requestId);
    }
    if (animationInfo === undefined) {
      setAnimationInfo([
        { obuInfo, jsTimestamp: performance.now() },
        { obuInfo, jsTimestamp: performance.now() },
      ]);
      return;
    } else {
      const nextTimestamp = performance.now();
      const timeDiff = nextTimestamp - animationInfo[1].jsTimestamp;
      const origin = animationInfo[1];
      const animationCallback = (timestamp: number) => {
        if (timestamp < nextTimestamp + timeDiff) {
          const factor = (timestamp - origin.jsTimestamp) / timeDiff;
          setAnimatedPosition(
            point(
              [
                origin.obuInfo.lon +
                  (obuInfo.lon - origin.obuInfo.lon) * factor,
                origin.obuInfo.lat +
                  (obuInfo.lat - origin.obuInfo.lat) * factor,
              ],
              {
                kphSpeed:
                  origin.obuInfo.kphSpeed +
                  (obuInfo.kphSpeed - origin.obuInfo.kphSpeed) * factor,
                heading:
                  origin.obuInfo.heading +
                  (obuInfo.heading - origin.obuInfo.heading) * factor,
              }
            )
          );
          setRequestId(requestAnimationFrame(animationCallback));
        }
      };
      setRequestId(requestAnimationFrame(animationCallback));
      setAnimationInfo([
        animationInfo[1],
        { obuInfo, jsTimestamp: nextTimestamp },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obuInfo]);

  return [animatedPosition, setObuInfo];
};
