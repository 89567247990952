import { useSelector, useDispatch } from "react-redux";
import { mapViewActions, selectMapboxTheme } from "../../store/mapView";
import { v2xMessageActions } from "../../store/v2xMessage";
import ToggleSettingsItem from "./ToggleSettingsItem";
import DropDownSettings from "./DropDownSettings";
import { MessageType } from "../../types/MessageTypes";
import {
  selectDarkMode,
  selectNavigationView,
  selectSatelliteView,
  selectIotCloudData,
} from "../../store/mapView";
import { selectToggleMsgTypes } from "../../store/v2xMessage";
import { t } from "i18next";
import { selectUsesMatchedLanes } from "../../store/coreLibrary";

const ToggleSettings = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const navigationView = useSelector(selectNavigationView);
  const satelliteView = useSelector(selectSatelliteView);
  const iotCloudData = useSelector(selectIotCloudData);
  const toggleMsgTypes = useSelector(selectToggleMsgTypes);
  const mapboxTheme = useSelector(selectMapboxTheme);
  const usingMatched = useSelector(selectUsesMatchedLanes);

  return (
    <>
      <h1 className="settings-header">{t("MapSettings")}</h1>
      <DropDownSettings />

      <ToggleSettingsItem
        className="darkMode"
        header="DarkMode"
        onChange={() => dispatch(mapViewActions.toggleDarkMode())}
        checked={darkMode}
        disabled={satelliteView || mapboxTheme.length > 0}
      />
      <ToggleSettingsItem
        header="NavigationView"
        onChange={() => dispatch(mapViewActions.toggleNavigationView())}
        checked={navigationView}
      />
      <ToggleSettingsItem
        header="SatelliteView"
        onChange={() => dispatch(mapViewActions.toggleSatelliteView())}
        checked={satelliteView}
        disabled={mapboxTheme.length > 0 || usingMatched}
      />
      <ToggleSettingsItem
        header="IOTCloudHamburgData"
        onChange={() => dispatch(mapViewActions.toggleIotCloudData())}
        checked={iotCloudData}
      />

      <h1 className="settings-header">{t("V2XSettings")}</h1>
      <ToggleSettingsItem
        header="CAMMessages"
        onChange={() =>
          dispatch(v2xMessageActions.toggleMsgTypes(MessageType.CAMS))
        }
        checked={toggleMsgTypes.CAMS}
      />
      <ToggleSettingsItem
        header="CPMMessages"
        onChange={() =>
          dispatch(v2xMessageActions.toggleMsgTypes(MessageType.CPMS))
        }
        checked={toggleMsgTypes.CPMS}
      />
      <ToggleSettingsItem
        header="DENMMessages"
        onChange={() =>
          dispatch(v2xMessageActions.toggleMsgTypes(MessageType.DENMS))
        }
        checked={toggleMsgTypes.DENMS}
      />
      <ToggleSettingsItem
        header="SPATEMMessages"
        onChange={() =>
          dispatch(v2xMessageActions.toggleMsgTypes(MessageType.SPATEMS))
        }
        checked={toggleMsgTypes.SPATEMS}
      />
      <ToggleSettingsItem
        header="IVIMMessages"
        onChange={() =>
          dispatch(v2xMessageActions.toggleMsgTypes(MessageType.IVIMS))
        }
        checked={toggleMsgTypes.IVIMS}
      />
    </>
  );
};

export default ToggleSettings;
