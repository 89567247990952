import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export default i18next.use(LanguageDetector).init({
  detection: { caches: [] },
  debug: false,
  fallbackLng: "en",
  resources: {
    en: {
      translation: {
        Impressum: "imprint",
        Datenschutz: "privacy",
        pedestrian: "pedestrian",
        vehicle: "vehicle",
        MQTTClientSettings: "MQTT Client Settings",
        GeneralAppSettings: "General App Settings",
        ChangeBrokerHost: "Change Broker Host",
        ChangeBrokerPort: "Change Broker Port",
        RateLimitedTopics: "Use Rate-Limited Topics",
        save: "save",
        MapSettings: "Map Settings",
        DarkMode: "Dark Mode",
        NavigationView: "Navigation View",
        SatelliteView: "Satellite View",
        IOTCloudHamburgData: "IOT Cloud Hamburg Data",
        UserFriendlyGlosa: "User-Friendly GLOSA",
        UsingMatched: "Match Lanes",
        CAMMessages: "CAM Messages",
        CPMMessages: "CPM Messages",
        DENMMessages: "DENM Messages",
        SPATEMMessages: "SPATEM Messages",
        IVIMMessages: "IVIM Messages",
        Wait: "Waiting for the next ",
        from: " from station ",
        matchedSelectorHint: "Not available when matching lanes",
        V2XSettings: "V2X Settings",
        hostInvalid: "The entered host is invalid.",
        hostEmpty: "Please enter a broker Host.",
        portInvalid: "The entered port is invalid.",
        portEmpty: "Please enter a port number.",
        denmDanger: "Danger!\nAccident{{distance}}!",
        denmAquaplaning: "Caution!\nSlippery road{{distance}}!",
        denmTrafficCondition: "Caution!\nSafety System Active Ahead{{distance}}!",
        denmWrongWayDriving: "Caution!\nWrong way driver{{distance}}!",
        denmWeatherCondition: "Caution!\nVisual Obstruction{{distance}}!",
        denmSlowVehicle: "Caution!\nSlow Vehicle{{distance}}!",
        denmCollision: "Caution!\nRisk of Collisions{{distance}}!",
        denmQueueEnd: "Caution!\nTraffic Jam{{distance}}!",
        denmWork: "Caution!\nRescue Work in Process{{distance}}!",
        denmVehicleApproaching:
          "Caution!\nEmergency Vehicle Approaching{{distance}}!",
        denmAnimal: "Caution!\nAnimal on the road{{distance}}!",
        denmObstacle: "Caution!\nObstacle on the road{{distance}}!",
        denmPedestrian: "Caution!\nPerson on the road{{distance}}!",
        denmRoadwork: "Caution!\nConstruction ahead{{distance}}!",
        denmBrokenDown: "Caution!\nBroken Down Vehicle{{distance}}!",
        denmPostCrash: "Caution!\nPost Crash{{distance}}!",
        denmStationary: "Caution!\nStationary Vehicle{{distance}}!",
        denmCaution: "Caution{{distance}}!",
        SelectMapStyle: "Select a Map Style",
        geolocationWarning:
          "Localisation only available for secure connections (HTTPS).",
        GlosaFreqInput: "GLOSA update frequency in ms",
        integerInvalid: "Please enter a valid integer",
        SpatemFreqInput: "Max. frequency of SPATEMs per station in ms",
        CamFreqInput: "Max. frequency of CAMs per station in ms",
        LaneCorridorWidthInput: "Width of GLOSA's approach corridors in m",
        FeatureTTLInput: "Validity time of GeoJSON feature on the map in ms",
        ApproachHeadingInput: "Heading tolerance of GLOSA in degrees",
        UsesV2xRx: "Use V2X RX protobuf protocol",
        UserFriendlyGLOSA: "Process GLOSA for user-friendliness",
        ShowDebugGlosa: "Display GLOSA debug information on map",
        ShowGlosaOnMap: "Display GLOSA on map",
        ShowObuPathHistory: "Display OBU's path history on map",
        ShowConsoleDebug: "Display WASM Core debug information in the console",
        DeveloperSettings: "Developer Settings",
        BugReporting: "Bug Reporting",
        send: "send",
        bugInput: "Enter your bug here!",
        bugReportEmpty: "Please enter a bug report!",
        bugReportError: "Failed to send bug report! Please ensure you have disabled all ad blockers in order to submit.",
        bugReportSuccess: "Thank you for submitting the bug report!",
        receivedLoopback: "Received Loopback "
      },
    },
    de: {
      translation: {
        Impressum: "Impressum",
        Datenschutz: "Datenschutz",
        pedestrian: "Fußgänger",
        vehicle: "Fahrzeuge",
        MQTTClientSettings: "MQTT-Client-Einstellungen",
        GeneralAppSettings: "Allgemeine App-Einstellungen",
        ChangeBrokerHost: "Änderung des Broker-Hosts",
        ChangeBrokerPort: "Änderung des Broker-Ports",
        RateLimitedTopics: "V2X-Nachrichtenfrequenz reduzieren",
        save: "Speichern",
        UsingMatched: "Spuren anpassen",
        MapSettings: "Map-Einstellungen",
        DarkMode: "Dunkler Modus",
        NavigationView: "Navigationsansicht",
        SatelliteView: "Satellitenansicht",
        IOTCloudHamburgData: "Zeige Daten der IOT Cloud Hamburg",
        UserFriendlyGlosa: "Benutzerfreundliche GLOSA",
        CAMMessages: "CAM-Nachrichten",
        CPMMessages: "CPM-Nachrichten",
        DENMMessages: "DENM-Nachrichten",
        SPATEMMessages: "SPATEM-Nachrichten",
        IVIMMessages: "IVIM-Nachrichten",
        Wait: "Warten auf die nächste ",
        from: " von Station ",
        V2XSettings: "V2X-Einstellungen",
        hostInvalid: "Der eingegebene Host ist nicht gültig.",
        hostEmpty: "Bitte einen Broker-Host eingeben.",
        portInvalid: "Der eingegebene Port ist nicht gültig.",
        portEmpty: "Bitte eine Port-Nummer eingeben.",
        denmDanger: "Gefahr!\nUnfall voraus{{distance}}!",
        denmAquaplaning: "Achtung!\nStraßenglätte{{distance}}!",
        denmTrafficCondition: "Achtung!\nKritische Fahrsituation voraus{{distance}}!",
        denmWrongWayDriving: "Achtung!\nFalschfahrer{{distance}}!",
        denmWeatherCondition: "Achtung!\nSchlechte Sicht{{distance}}!",
        denmSlowVehicle: "Achtung!\nLangsames Fahrzeug voraus{{distance}}!",
        denmCollision: "Achtung!\nRisiko einer Kollision{{distance}}!",
        denmQueueEnd: "Achtung!\nStauende{{distance}}!",
        denmWork: "Achtung!\nRettungsarbeiten voraus{{distance}}!",
        matchedSelectorHint: "Nicht verfügbar bei angepassten Spuren",
        denmVehicleApproaching:
          "Achtung!\nEinsatzfahrzeug nähert sich{{distance}}!",
        denmAnimal: "Achtung!\nTiere auf der Fahrbahn{{distance}}!",
        denmObstacle: "Achtung!\nGegenstände auf der Fahrbahn{{distance}}!",
        denmPedestrian: "Achtung!\nPersonen auf der Fahrbahn{{distance}}!",
        denmRoadwork: `Achtung!\nBaustelle voraus{{distance}}!`,
        denmBrokenDown: "Achtung!\nLiegengebliebenes Fahrzeug{{distance}}!",
        denmPostCrash: "Achtung!\nNach einem Unfall{{distance}}!",
        denmStationary: "Achtung!\nStehendes Fahrzeug voraus{{distance}}!",
        denmCaution: "Achtung{{distance}}!",
        SelectMapStyle: "Wähle einen Map Style",
        geolocationWarning:
          "Lokalisierung ist nur bei sicherer Verbindung (HTTPS) verfügbar.",
        GlosaFreqInput: "GLOSA-Updatefrequenz in ms",
        integerInvalid: "Bitte gib einen validen Integerwert ein",
        SpatemFreqInput: "Max. SPATEM-Frequenz pro ITS-Station in ms",
        CamFreqInput: "Max. CAM-Frequenz pro ITS-Station in ms",
        LaneCorridorWidthInput: "Breite der GLOSA-Signalfurten in m",
        FeatureTTLInput: "Lebensdauer der GeoJSON-Features auf der Karte in ms",
        ApproachHeadingInput: "Fahrtrichtungstoleranz von GLOSA in Grad",
        UserFriendlyGLOSA: "Zeige userfreundliches GLOSA",
        ShowDebugGlosa: "Zeige GLOSA-Debuginformationen auf der Karte",
        ShowGlosaOnMap: "Zeige GLOSA auf der Karte",
        ShowObuPathHistory: "Zeige OBU-Pfadverlauf auf der Karte",
        ShowConsoleDebug: "Zeige WASM-Core-Debuginformationen in der Konsole",
        DeveloperSettings: "Entwickler-Einstellungen",
        BugReporting: "Fehler melden",
        send: "Senden",
        bugInput: "Hier Fehler beschreiben!",
        bugReportEmpty: "Bitte Fehler beschreiben!",
        bugReportError: "Fehler beim Senden des bug reports! Bitte stelle sicher, dass alle Werbeblocker deaktiviert sind, um den Bericht senden zu können.",
        bugReportSuccess: "Vielen Dank für das Einreichen des bug reports!",
        receivedLoopback: "Eingehende Loopback-"
      },
    },
  },
});
