import { createSlice } from "@reduxjs/toolkit";
import { appSettings } from "../config/AppSettings";
import { RootState } from ".";

interface IMapInfoState {
  openSidebar: boolean;
  showEtsi: boolean;
  openDataView: boolean;
}

const initialMapInfoState: IMapInfoState = {
  openSidebar: appSettings.openSidebar,
  showEtsi: true,
  openDataView: appSettings.openDataView,
};

const mapInfoSlice = createSlice({
  name: "mapInfo",
  initialState: initialMapInfoState,
  reducers: {
    toggleSidebar(state) {
      state.openSidebar = !state.openSidebar;
    },

    showEtsi(state) {
      state.showEtsi = true;
    },

    hideEtsi(state) {
      state.showEtsi = false;
    },

    openDataView(state) {
      state.openDataView = true;
    },

    closeDataView(state) {
      state.openDataView = false;
    },
  },
});

export const selectOpenSidebar = (state: RootState) =>
  state.mapInfo.openSidebar;

export const selectShowEtsi = (state: RootState) => state.mapInfo.showEtsi;

export const selectOpenDataView = (state: RootState) =>
  state.mapInfo.openDataView;

export const mapInfoActions = mapInfoSlice.actions;
export default mapInfoSlice.reducer;
