import { useSelector, useDispatch } from "react-redux";
import { mqttConnectionActions, selectTopicSet } from "../../store/mqttConnection";
import MqttSettingsItem from "./InputSettingsItem";
import { regexMqttHost, regexIsInt } from "../../helper/regex";
import { selectMqttHost, selectMqttPort } from "../../store/mqttConnection";
import { t } from "i18next";
import ToggleSettingsItem from "./ToggleSettingsItem";

const MqttSettings = () => {
  const dispatch = useDispatch();
  const mqttHost = useSelector(selectMqttHost);
  const mqttPort = useSelector(selectMqttPort);
  const usesRxRed = useSelector(selectTopicSet);

  return (
    <>
      <h1>{t("MQTTClientSettings")}</h1>
      <MqttSettingsItem
        header="ChangeBrokerHost"
        type="text"
        placeholder="Host"
        currentValue={mqttHost}
        setValue={(brokerHost: string) =>
          dispatch(mqttConnectionActions.connectMqttHost(brokerHost))
        }
        checkValidInput={(input: string) => !regexMqttHost.test(input)}
        invalidInputMessage="hostInvalid"
        emptyInputMessage="hostEmpty"
        testIdPrefix="host-"
      />
      <MqttSettingsItem
        header="ChangeBrokerPort"
        type="number"
        placeholder="Port"
        currentValue={mqttPort}
        setValue={(brokerPort: number) =>
          dispatch(mqttConnectionActions.connectMqttPort(brokerPort))
        }
        checkValidInput={(input: string) => !regexIsInt.test(input)}
        invalidInputMessage="portInvalid"
        emptyInputMessage="portEmpty"
        testIdPrefix="port-"
      />
      <ToggleSettingsItem
        header={"RateLimitedTopics"}
        onChange={() =>
          dispatch(mqttConnectionActions.selectTopicSet(!usesRxRed))
        }
        checked={usesRxRed ?? false}
      />
    </>
  );
};

export default MqttSettings;
