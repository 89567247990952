export const darkModeActive = () =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const onColorSchemeChange = (
  listener: (darkModeActive: boolean) => void
) => {
  if (window.matchMedia != null) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () =>
        listener(window.matchMedia("(prefers-color-scheme: dark)").matches)
      );
  }
};

export const themeBasedClass = (
  darkMode: boolean,
  satelliteView: boolean
): string => {
  if (darkMode && !satelliteView) return "darkmode";
  else if (satelliteView) return "satellite-view";
  return "";
};
