import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import mqttConnectionReducer from "./mqttConnection";
import mapViewReducer from "./mapView";
import v2xMessageReducer from "./v2xMessage";
import mapInfoReducer from "./mapInfo";
import coreReducer from "./coreLibrary";
import { CoreSettings } from "../types/CoreWorker";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["mqttConnection", "mapView", "v2xMessage", "coreLibrary"],
};

const reducer = combineReducers({
  mqttConnection: mqttConnectionReducer,
  mapView: mapViewReducer,
  v2xMessage: v2xMessageReducer,
  mapInfo: mapInfoReducer,
  coreLibrary: coreReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const selectCoreSettings = (state: RootState): CoreSettings => ({
  brokerUrl: `ws://${state.mqttConnection.mqttHost}:${state.mqttConnection.mqttPort}`,
  usesRateReducedTopics: state.mqttConnection.usesRxRed,
  glosaFrequencyMs: state.coreLibrary.glosaFrequencyMs,
  spatemMaxFreqMs: state.coreLibrary.spatemMaxFreqMs,
  usesV2xRxWrapper: state.coreLibrary.usesV2xRxWrapper,
  laneCorridorWidthM: state.coreLibrary.laneCorridorWidthM,
  featureLifetimeMs: state.coreLibrary.featureLifetimeMs,
  camMaxFreqMs: state.coreLibrary.camMaxFreqMs,
  approachHeadingTolerance: state.coreLibrary.approachHeadingTolerance,
  usingMatchedLanes: state.coreLibrary.usingMatchedLanes,
  userFriendlyGlosa: state.coreLibrary.userFriendlyGlosa,
  debugGlosa: state.coreLibrary.debugGlosa,
  showObuPathHistory: state.coreLibrary.showObuPathHistory,
  showConsoleDebug: state.coreLibrary.showConsoleDebug,
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export default store;
