import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mapInfoActions, selectShowEtsi } from "../../store/mapInfo";
import { selectOpenDataView, selectOpenSidebar } from "../../store/mapInfo";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { screenPointToArea } from "../../helper/screenPointToArea";
import { IV2XProvider } from "../../services/V2XProvider";
import { IMapboxMap } from "../../types/IMapboxMap";
import { appSettings } from "../../config/AppSettings";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type DataViewProps = {
  map: IMapboxMap;
  v2xProvider: IV2XProvider;
};

type MessageByStation = {
  type: string;
  id: number;
  secondary?: number;
}

/**
 * LoopbackMessage
 * @typedef {Object} LoopbackMessage
 * @description Describes a loopback message to be shown for a limited time
 * @see Map
 * @property {string} type - Printable message type coming in over loopback, either "CPM" or "DENM"
 * @property {string} fullMessage - Stringified, indented, JSON of the entire ETSI message
 * @property {number} timoutId  - ID of the timout set to cancel the message display
 */
type LoopbackMessage = {
  type: string;
  fullMessage: string;
  timeoutId: string | number | NodeJS.Timeout;
}


export const DataView = ({ map, v2xProvider }: DataViewProps) => {
  /**
   * @var loopbackMessage
   * @description State of received loopback messages
   */
  const [loopbackMessage, setLoopbackMessage] = useState<LoopbackMessage>();
  const [selection, setSelection] = useState<MessageByStation[]>([]);
  const [subscribed, setSubscribed] = useState<MessageByStation>();
  const [etsiMsg, setEtsiMsg] = useState<string>();
  const dispatch = useDispatch();
  const showEtsi = useSelector(selectShowEtsi);
  const openDataView = useSelector(selectOpenDataView);
  const openSidebar = useSelector(selectOpenSidebar);
  const mobileWidth = window.innerWidth < 500 && openSidebar;

  useEffect(() => {
    if (etsiMsg !== undefined && !mobileWidth) {
      if (openSidebar && !openDataView) {
        dispatch(mapInfoActions.toggleSidebar());
        dispatch(mapInfoActions.showEtsi());
      }

      dispatch(mapInfoActions.openDataView());
    } else if (openSidebar && !openDataView && !showEtsi && !mobileWidth) {
      dispatch(mapInfoActions.showEtsi());
    } else dispatch(mapInfoActions.closeDataView());
  }, [etsiMsg, openSidebar, openDataView, showEtsi, mobileWidth, dispatch]);

  useEffect(() => {
    v2xProvider.onLoopbackMsg((type, fullMessage) => {
      setLoopbackMessage(state => {
        if (state !== undefined) {
          clearTimeout(state.timeoutId)
        }
        return {
          type,
          fullMessage,
          timeoutId: setTimeout(() => setLoopbackMessage(undefined), 5000)
        }
      })
    })

    map.on("click", (ev) => {
      setSubscribed(undefined);
      setEtsiMsg(undefined);
      setSelection(
        map
          .queryRenderedFeatures(screenPointToArea(ev.point), {
            layers: [
              "cam-symbols",
              "cam-paths",
              "denm-symbols",
              "denm-traces",
              "green-lanes",
              "red-lanes",
              "yellow-lanes",
              "dark-lanes",
              "cpm-object-outlines",
              "cpm-areas",
              "cpm-sensors",
              "ivim-refpoints",
              "ivim-zones",
              "ivim-lanes",
            ],
          })
          .flatMap((feature) => {
            const uuidProp = feature.properties?.uuid;
            if (uuidProp === undefined) return [];
            const type = parseInt(uuidProp.substring(0, uuidProp.length - 18));
            const station = parseInt(
              uuidProp.substring(uuidProp.length - 18, uuidProp.length - 8)
            );
            const secondary = parseInt(
              uuidProp.substring(uuidProp.length - 8, uuidProp.length - 3)
            );
            if (type >= 10 && type <= 13) {
              return [
                { type: "SPAT", id: station },
                { type: "MAP", id: station },
              ];
            } else if (type === 3) {
              return [{ type: "CAM", id: station }];
            } else if (type === 4) {
              return [{ type: "DENM", id: station }];
            } else if (type === 5) {
              return [{ type: "CPM", id: station }];
            } else if (type === 8) {
              return [{ type: "IVIM", id: station, secondary }];
            } else {
              return [];
            }
          })
          .filter(
            (msg, i, self) =>
              self.findIndex((m) => m.id === msg.id && m.type === msg.type) ===
              i
          )
      );
    });
  }, [v2xProvider, map]);

  useEffect(() => {
    if (subscribed !== undefined) {
      v2xProvider.getLatestMessage(
        messageTypeIndexMapping[subscribed.type],
        subscribed.id,
        subscribed.secondary,
        setEtsiMsg,
      );
    }
  }, [subscribed, v2xProvider]);

  return (
    <div>
      {loopbackMessage && (
        <span className="span-overlay-message" onClick={() => {
          setSelection([{ type: "Loopback", id: 0 }])
          setSubscribed({ type: "Loopback", id: 0 })
          setEtsiMsg(loopbackMessage.fullMessage)}
        }>
          {t("receivedLoopback")}{loopbackMessage.type}
        </span>
      )}
      {subscribed !== undefined && !openSidebar && (
        <div
          className="refresh-button"
          onClick={() => {
            setSubscribed((sub) => (sub ? { ...sub } : sub));
          }}
        >
          <FontAwesomeIcon
            icon={faRefresh}
            className="fa-3x"
            data-testid="arrow-icon"
          />
        </div>
      )}
      <AnimatePresence>
        {selection.length > 0 ? (
          <motion.div
            layout
            transition={{ duration: 0.3 }}
            animate={{ x: openSidebar ? appSettings.sidebarXAxis : 0 }}
            className="data-view"
            data-testid="data-view"
          >
            {subscribed !== undefined ? (
              <div>
                {showEtsi && (
                  <>
                    {openDataView ? (
                      <pre style={{ maxWidth: appSettings.sidebarXAxis - 50 }}>
                        {etsiMsg}
                      </pre>
                    ) : (
                      <pre style={{ maxWidth: 220 }}>
                        {t("Wait") + `${subscribed.type}`} <br />
                        {t("from") + `${subscribed.id} ...`}
                      </pre>
                    )}
                  </>
                )}
              </div>
            ) : (
              selection.map((el, i) => (
                <h2
                  className="properties"
                  key={i}
                  onClick={() => setSubscribed(el)}
                >
                  {el.type} {el.id}
                </h2>
              ))
            )}
          </motion.div>
        ) : (
          <></>
        )}
      </AnimatePresence>
    </div>
  );
};

const messageTypeIndexMapping: { [key: string]: number } = {
  DENM: 1,
  CAM: 2,
  SPAT: 4,
  MAP: 5,
  IVIM: 8,
  CPM: 14,
};

export default DataView;
