/**
 * @module Map Component
 * @description Manages the rendering of V2X data to different layers of the Mapbox map
 */
import ReactMapGL, { Map as MapboxMap } from "mapbox-gl";
import { useRef, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  selectMapboxTheme,
  selectDarkMode,
  selectNavigationView,
  selectSatelliteView,
  selectIotCloudData,
} from "../../store/mapView";
import { selectOpenSidebar, selectOpenDataView } from "../../store/mapInfo";
import { selectToggleMsgTypes } from "../../store/v2xMessage";
import { appSettings } from "../../config/AppSettings";
import { Layer } from "./Layer";
import DataView from "./DataView";
import LegendItem from "./LegendItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow, faCompass } from "@fortawesome/free-solid-svg-icons";
import "mapbox-gl/dist/mapbox-gl.css"; // prevents warning in console -> missing css declarations
import { IV2XProvider } from "../../services/V2XProvider";
import { useAnimatedPosition } from "../../hooks/useAnimatedPosition";
import { GeojsonSource } from "../../types/GeojsonSource";
import { IMapboxMap } from "../../types/IMapboxMap";
import { determineMapStyle, themeBasedClass } from "../../styles/mapStyle";
import { displayMatchedLanes } from "../../helper/displayMatchedLanes";
import { t } from "i18next";
import { selectShowDebugGlosa, selectShowObuPathHistory, selectUsesMatchedLanes } from "../../store/coreLibrary";
import { ObuInfo } from "../../types/CoreWorker";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { isValidLonLat } from "../../helper/validLonLat";

// ReactMapGL requires different configurations in unit tests and deployments
if (window.env !== undefined) {
  /* eslint-disable import/no-webpack-loader-syntax */
  // @ts-ignore-next-line
  ReactMapGL.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
  ReactMapGL.accessToken = window.env.REACT_APP_ACCESS_TOKEN;
}

type MapProps = {
  v2xService: IV2XProvider;
  mapboxMap?: IMapboxMap;
};

/**
 * MapProps
 * @typedef {Object} MapProps
 * @description Props passed to the Map component
 * @see Map
 * @property {IV2XProvider} v2xService - Source of V2X messages
 * @property {IMapboxMap} [mapboxMap]  - Mapbox map rendered in the UI
 */
/**
 * Map
 * @alias Map
 * @description Functional React component that renders a map with V2X data
 * @param {MapProps} mapProps - data source and map framework passed via dependency injection
 * @see MapProps
 */
const Map = ({ v2xService, mapboxMap }: MapProps) => {
  /**
   * @var mapContainer
   * @description Reference to the HTML div containing the Mapbox map
   */
  const mapContainer = useRef<HTMLDivElement>(document.createElement("div"));
  /**
   * @var map
   * @description Reference to the Mapbox map instance
   */
  const [map, setMap] = useState<IMapboxMap | undefined>();
  /**
   * @var showLegend
   * @description State of visibility of the CPM legend
   */
  const [showLegend, setShowLegend] = useState(false);
  /**
   * @var cameraTracking
   * @description Defines whether the camera follows the user's location and heading
   */
  const [cameraTracking, setCameraTracking] = useState(true);
  /**
   * @var mapboxTheme
   * @description Current theme of the rendered map
   */
  const mapboxTheme = useSelector(selectMapboxTheme);
  /**
   * @var darkMode
   * @description Defines whether the UI should be rendered in dark colors
   */
  const darkMode = useSelector(selectDarkMode);
  /**
   * @var navigationView
   * @description Current selection of the camera perspective, false for a top-down perspective, true for
   a POV-perspective at an angle */
  const navigationView = useSelector(selectNavigationView);
  /**
   * @var satelliteView
   * @description Defines whether the map should render satellite imagery
   */
  const satelliteView = useSelector(selectSatelliteView);
  /**
   * @var iotCloudData
   * @description Defines whether data from HH's IoT Cloud should be rendered on the map
   */
  const iotCloudData = useSelector(selectIotCloudData);
  /**
   * @var openSidebar
   * @description State of visibility of the app's sidebar
   */
  const openSidebar = useSelector(selectOpenSidebar);
  /**
   * @var openDataView
   * @description State of visibility of the app's data view
   */
  const openDataView = useSelector(selectOpenDataView);
  /**
   * @var toggleMsgTypes
   * @description Current selection of message types that should be rendered on the map
   */
  const toggleMsgTypes = useSelector(selectToggleMsgTypes);
  /**
   * @var usingMatched
   * @description Defines whether an intersection's lanes should be matched to the OpenStreetMap dataset in order to create a cleaner rendering
   */
  const usingMatched = useSelector(selectUsesMatchedLanes);
  /**
   * @var showsGlosaDebug
   * @description Defines whether intersection areas and approach corridors used by the GLOSA algorithm should be drawn to the map
   */
  const showsGlosaDebug = useSelector(selectShowDebugGlosa);
  /**
   * @var showsObuPath
   * @description Defines whether the path history of the on-board unit should be displayed
   */
  const showsObuPath = useSelector(selectShowObuPathHistory);
  /**
   * @var obu
   * @description Current on-board unit (OBU) metadata
   */
  const [obu, setObu] = useState({
    lat: 53.550748669886566, // - WGS84 latitude of the OBU's location
    lon: 9.936546820994705, // - WGS84 latitude of the OBU's location
    kphSpeed: 0, // - Speed of the OBU's movement in kph
    heading: 0, // - Heading of the OBU's movement in degrees from 0 north
    time: Date.now(), // - OBU's current system time
  });
  /**
   * @var showGeolocationWarning
   * @description State of visibility of the app's geolocation warning. A geolocation warning is shown when the user denies permissions to access the browser location.
   */
  const [showGeolocationWarning, setShowGeolocationWarning] = useState(false);
  /**
   * @var lastUpdateTime
   * @description Timestamp of last geolocation update for switching between OBU and browser location
   */
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  /**
   * @var animatedPosition
   * @description Animated position on the on-board unit
   */
  const [animatedPosition, setAnimatedPosition] = useAnimatedPosition();
  /**
   * @var iconClass
   * @description Returns a className based on the current theming
  */
  const iconClass = themeBasedClass(darkMode, mapboxTheme);
  // Hook for initial setup. Runs once.
  // Sets up listeners for V2X data and geoposition.
  // Initializes Mapbox map.
  useEffect(() => {
    const createMap = async () => {
      const mMap = mapboxMap
        ? mapboxMap
        : new MapboxMap({
          container: mapContainer.current,
          style: await determineMapStyle(
            mapboxTheme,
            darkMode,
            navigationView,
            satelliteView
          ),
          center: [appSettings.mapPos.lon, appSettings.mapPos.lat],
          zoom: appSettings.mapZoom,
        });

      mMap.on("load", () => {
        mMap
          .on("drag", () => setCameraTracking(false))
          .on("wheel", () => setCameraTracking(false));

        setMap(mMap);
      });
    };

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        if (!("geolocation" in navigator)) {
          setShowGeolocationWarning(true);
          setTimeout(() => setShowGeolocationWarning(false), 3000);
        }

        // If the OBU position has not been updated within the last n seconds (see app settings), fall back to the browser location for the user's position
        if (
          Date.now() - lastUpdateTime >
          appSettings.defaultPermissionRequest
        ) {
          const { latitude: userLat, longitude: userLon } = position.coords;
          const deviceLocation = {
            lat: userLat,
            lon: userLon,
            kphSpeed: 0,
            heading: 0,
            time: Date.now(),
          };
          setAnimatedPosition(deviceLocation);
          setObu(deviceLocation);
        }
      },
      null,
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      }
    );

    createMap();

    v2xService.onObuInfo((obuInfo: ObuInfo) => {
      setAnimatedPosition(obuInfo);
      setObu(obuInfo);
      setLastUpdateTime(Date.now());
    });

    // Remove callbacks when component is torn down
    return () => {
      map?.remove();
      setMap(undefined);
      navigator.geolocation.clearWatch(watchId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs whenever a change occurs that affects the map theme.
  // Determines applicable theme and applies it to the map.
  useEffect(() => {
    async function setMapStyle() {
      const style = await determineMapStyle(
        mapboxTheme,
        darkMode,
        navigationView,
        satelliteView
      );
      map?.setStyle(style);
    }
    setMapStyle();
  }, [mapboxTheme, satelliteView, darkMode, navigationView, map]);

  // Runs whenever a change occurs that affects the map theme.
  // Toggles lane matching. Matched lanes have to be re-applied everytime
  // the map theme changes.
  useEffect(() => {
    if (map !== undefined) {
      displayMatchedLanes(
        v2xService,
        map,
        usingMatched && toggleMsgTypes.SPATEMS
      );
    }
  }, [
    mapboxTheme,
    satelliteView,
    darkMode,
    navigationView,
    toggleMsgTypes,
    map,
    usingMatched,
    v2xService,
  ]);

  const updateCamera = useCallback(() => {
    if (navigationView) {
      map?.easeTo({
        pitch: appSettings.defaultPitch,
        duration: appSettings.defaultEaseDuration,
      });
      setCameraTracking(true);
    }
  }, [map, navigationView]);

  // Runs whenever a change in the camera perspective should occur.
  useEffect(updateCamera, [navigationView, map, updateCamera]);

  // Runs whenever the user's position on the map should change.
  // Apart from actual position updates this happens when the map container's size changes.
  useEffect(() => {
    if (!isValidLonLat(obu.lon, obu.lat)) return;

    if (cameraTracking && obu) {
      map?.easeTo({
        pitch: navigationView ? appSettings.defaultPitch : 0,
        padding: {
          top: 300,
          bottom: 0,
          left:
            openSidebar || (openDataView && !(window.innerWidth < 500))
              ? appSettings.sidebarXAxis
              : 0,
          right: 0,
        },
        animate: true,
        duration: 1000,
        zoom: Math.max(16.5 - 0.01 * (obu.kphSpeed ?? 0), 14),
        bearing: obu.heading,
        center: [obu.lon, obu.lat],
      });
    }
  }, [map, cameraTracking, obu, navigationView, openSidebar, openDataView]);

  return (
    <>
      {showLegend && (
        <div className="legend">
          <LegendItem msgType="pedestrian" />
          <LegendItem msgType="vehicle" />
        </div>
      )}

      {showGeolocationWarning && (
        <span className="span-overlay-message">
          {t("geolocationWarning")}
        </span>
      )}

      <div
        className="map-container"
        data-testid="map-container"
        ref={mapContainer}
      />
      {map != null && (
        <>
          {[
            {
              src: GeojsonSource.GLOSA_DEBUG,
              v2xMsgType: showsGlosaDebug,
            },
            {
              src: GeojsonSource.IVIM,
              v2xMsgType: toggleMsgTypes.IVIMS,
            },
            {
              src: GeojsonSource.OBU_PATH,
              v2xMsgType: showsObuPath,
            },
            {
              src: GeojsonSource.DARK_LANES,
              v2xMsgType: toggleMsgTypes.SPATEMS,
            },
            {
              src: GeojsonSource.YELLOW_LANES,
              v2xMsgType: toggleMsgTypes.SPATEMS,
            },
            {
              src: GeojsonSource.GREEN_LANES,
              v2xMsgType: toggleMsgTypes.SPATEMS,
            },
            {
              src: GeojsonSource.RED_LANES,
              v2xMsgType: toggleMsgTypes.SPATEMS,
            },
            {
              src: GeojsonSource.INTERSECTION_REFPT,
              v2xMsgType: toggleMsgTypes.SPATEMS,
            },
            {
              src: GeojsonSource.CAM,
              v2xMsgType: toggleMsgTypes.CAMS,
            },
            {
              src: GeojsonSource.DENM,
              v2xMsgType: toggleMsgTypes.DENMS,
            },
            {
              src: GeojsonSource.IOT_CLOUD_DATA,
              v2xMsgType: iotCloudData,
            },
          ].map(({ src, v2xMsgType }, i) => (
            <Layer
              key={i}
              showMessages={v2xMsgType}
              mapboxMap={map}
              geojsonSource={src}
              v2xService={v2xService}
            />
          ))}
          <Layer
            showMessages={toggleMsgTypes.CPMS}
            mapboxMap={map}
            geojsonSource={GeojsonSource.CPM}
            v2xService={v2xService}
            onUpdate={(update) => {
              if (update && update.features.length > 0 && toggleMsgTypes.CPMS) {
                setShowLegend(true);
              } else {
                setTimeout(() => {
                  setShowLegend(false);
                }, 2000);
              }
            }}
          />
          <Layer
            showMessages={true}
            mapboxMap={map}
            geojsonSource={GeojsonSource.OBU_POS}
            geojsonData={animatedPosition}
            v2xService={v2xService}
          />
          <DataView map={map} v2xProvider={v2xService} />
        </>
      )}

      <div
        className="valid-coords"
        data-testid="valid-coords"
        style={{ visibility: isValidLonLat(obu.lon, obu.lat) ? "hidden" : "visible" }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="fa-2x"
          data-testid="valid-coords-icon"
        />
      </div>

      <div className={`cit-one-logos ${iconClass === "satellite-view" ? "satellite-view" : ""}`}>
        <img src={iconClass === "darkmode" ? "icon/cit_dark_logo.png" : "icon/cit_logo.svg"} alt="CiT One Logo" />
      </div>

      <div
        className="camera-tracking"
        data-testid="camera-tracking"
        onClick={() => setCameraTracking(!cameraTracking)}
      >
        {cameraTracking ? (
          <FontAwesomeIcon
            icon={faCompass}
            className="fa-3x"
            data-testid="compass-icon"
          />
        ) : (
          <FontAwesomeIcon
            icon={faLocationArrow}
            className="fa-3x"
            data-testid="arrow-icon"
          />
        )}
      </div>
    </>
  );
};

export default Map;
